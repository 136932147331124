import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpMethod } from '../../core/enums/http-handlers';
import { AppService } from '../../core/services/app.service';
import { CommonService } from '../../core/services/common.service';
import { EmployeeService } from '../../core/services/employee.service';
import { SearchJobsService } from '../../core/services/search-jobs.service';
import { SpinnerService } from '../../core/services/spinner.service';
import { EmployerRegisterComponent } from '../user-management/employer/employer-register/employer-register.component';
import { LoginComponent } from '../user-management/jobSeeker/login/login.component';
import { RegisterComponent } from '../user-management/jobSeeker/register/register.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  // form models
  searchJob: FormGroup;
  emailForm: FormGroup;

  submitted = false;

  blogsData: any = [];

  constructor(
    private commonService: CommonService,
    private fb: FormBuilder,
    private route: Router,
    public searchJobsService: SearchJobsService,
    public appService: AppService,
    private spinnerService: SpinnerService
  ) {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if(loginData) {
      switch (loginData.roleName) {
        case 'employee':
          this.route.navigateByUrl('/auth/employee/home');
          break;
          default:
          this.route.navigateByUrl('/auth/employer/home');
          break;
      }
    }
    this.searchJobsService.getJobSearchDropdownData();
    this.commonService.loginRoute()
    this.getAllBlogDetails();
  }

  ngOnInit(): void {
    this.searchJob = this.fb.group({
      jobTitle: ['', Validators.required],
      location: [''],
      searchType: ['BASIC'],
      isBrowseJobs: [false]
    });

    this.emailForm = this.fb.group({
      Email: ['', Validators.compose([Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$"),])],
    });

  }

  searchJobs(job?) {
    if (job) {
      this.searchJob.patchValue({
        jobTitle: job
      })
    } else {
      this.searchJob.patchValue({
        isBrowseJobs: true
      })
    }
    this.submitted = true;
    // if(this.searchJob.invalid) {
    //   return
    // }
    this.route.navigate(['job-search'], { queryParams: this.searchJob.value })
  }

  getAllBlogDetails() {
    const url = `${this.appService.getAllBlogDetails}?pageNumber=${1}&resultsPerPage=${5}&blogCategoryId=0`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
      showSpinner: false
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.data && res.data.hasOwnProperty('blogsData') && res.data.blogsData && res.data.blogsData.length) {
        this.blogsData = res.data.blogsData;
      }
    }));
  }

  saveContactDetails() {
    this.submitted = false;
    if(this.emailForm.invalid) {
      this.submitted = true;
      return;
    }
    const url = `${this.appService.saveContactDetails}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: {"email":this.emailForm.value.Email}
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      this.commonService.openSnackBar(res.message, 'toaster-success');
      this.emailForm.reset();
    }));
  }

  browserJobs() {
    this.route.navigateByUrl('find-Job')
  }
  clearValue(key) {
    this.searchJob.patchValue({
      [key]: ''
    })
  }

  setValues(value, key, id?) {
    this.searchJob.patchValue({
      [key]: id ? value[id] : value
    })
  }


  login() {
    this.commonService.openDialog({ template: LoginComponent}, (res) => { }) 
  }

  register() {
    this.commonService.openDialog({ template: RegisterComponent}, (res) => { }) 
  }

  registerEmployee() {
    this.commonService.openDialog({ template: EmployerRegisterComponent}, (res) => { }) 
  }

  clickHereBlogs(blog) {
    this.route.navigate(['/blog', blog.blogCategoryId])
  }

}
