import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../../../core/services/common.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RegisterComponent } from '../register/register.component';
import { AppService } from '../../../../core/services/app.service';
import { HttpMethod } from '../../../../core/enums/http-handlers';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadResumeComponent } from '../../../../shared/components/upload-resume/upload-resume.component';
import { TokenService } from '../../../../core/services/token.service';
import { EmployeeService } from '../../../../core/services/employee.service';
import { WhiteSpaceValidator } from '../../../../core/utilities/validation';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  // login form model
  loginForm: FormGroup;

  // to hide and show password
  hide = true;

  // to show input error messgae
  submitted = false;

  // show server error
  showServerErrors = {
    email: '',
    password: ''
  }

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private route: Router,
    private router: ActivatedRoute,
    private appService: AppService,
    public dialogRef: MatDialogRef<LoginComponent>,
    private spinnerService: SpinnerService,
    private tokenService: TokenService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.loginFormGroup();
    this.loginForm.get('email').valueChanges.subscribe(res => {
      this.showServerErrors ? this.showServerErrors.email = '' : null
    })
    this.loginForm.get('password').valueChanges.subscribe(res => {
      this.showServerErrors ? this.showServerErrors.password = '' : null
    })
  }

  loginFormGroup() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$")])],
      password: ['', Validators.compose([Validators.required, WhiteSpaceValidator.cannotContainSpace])],
      remindMe: [false]
    });
  }

  getPassword() {
    this.loginForm.patchValue({
      remindMe: false
    })
    let savedLogin = localStorage.getItem('logins');
    if (savedLogin) {
      let data = this.commonService.decryptData(savedLogin);
      let obj = data.find(res => res.email == this.loginForm.value.email)
      if (obj) {
        this.loginForm.patchValue({
          password: obj.password,
          remindMe: true
        })
      }
    }
  }

  loginSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const url = this.appService.loginEmployee;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: this.loginForm.value
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag === 'showControlError') {
        this.showServerErrors = res.data;
      } else if (statusFlag) {
        if (this.loginForm.value.remindMe) {
          let savedLogins = [];
          let savedLogin = localStorage.getItem('logins');
          if (savedLogin) {
            let data = this.commonService.decryptData(savedLogin);
            if (data) {
              savedLogins = data;
            }
            savedLogins = savedLogins.filter(el => el.email != this.loginForm.value.email)
            savedLogins.push({ email: this.loginForm.value.email, password: this.loginForm.value.password })
          } else {
            savedLogins = [{ email: this.loginForm.value.email, password: this.loginForm.value.password }]
          }
          localStorage.setItem('logins', this.commonService.encryptData(savedLogins));
        } else {
        let savedLogins = [];
        let savedLogin = localStorage.getItem('logins');
        if (savedLogin) {
          let data = this.commonService.decryptData(savedLogin);
          if (data) {
            savedLogins = data;
          }
          savedLogins = savedLogins.filter(el => el.email != this.loginForm.value.email)
        }
        localStorage.setItem('logins', this.commonService.encryptData(savedLogins));
      }
        this.dialogRef.close();
        if (res && res.hasOwnProperty('data')) {
          if (res.data.hasOwnProperty('accessToken')) {
            localStorage.setItem('loginData', JSON.stringify(res.data));
            this.tokenService.saveToken(res.data.accessToken);
          }
          if (res.data.hasOwnProperty('refreshtoken')) {
            this.tokenService.saveRefreshToken(res.data.refreshtoken);
          }
          if (res.data.hasOwnProperty('userProfilePicPath')) {
            this.employeeService.profilePicPath = res.data.userProfilePicPath;
          }
          if(this.route.routerState.snapshot.url.includes('/job-search-details/') || this.route.routerState.snapshot.url.includes('/job-search?')) {
            if(this.route.routerState.snapshot.url.includes('/job-search-details/')) {
              localStorage.setItem('refresh', 'true');
            }
            const id = this.route.routerState.snapshot.url.split("?")[0].split("/")[2]
              if (id) {
                this.route.navigate(['auth/employee/search-jobs/job-details', id], { queryParams:  this.router.queryParams['_value'] });
              } else {
                this.route.navigate(['auth/employee/search-jobs/job-results'], { queryParams:  this.router.queryParams['_value'] });
              }
          } else {
            if (res.data.id === 0) {
              this.route.navigateByUrl('/auth/employee/create-myprofile');
            } else {
              this.route.navigateByUrl('/auth/employee/home');
            }
          }
        }

      }
    }));
  }


  showForgotPwdModel() {
    this.dialogRef.close();
    this.commonService.openDialog({ template: ForgotPasswordComponent }, (res) => { })
  }

  showRegisterModel() {
    this.dialogRef.close();
    this.commonService.openDialog({ template: RegisterComponent }, (res) => { })
  }

}
