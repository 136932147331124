import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../../../core/services/common.service';
import { AppService } from '../../../../core/services/app.service';
import { HttpMethod } from '../../../../core/enums/http-handlers';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { EmployerForgotPasswordComponent } from '../employer-forgot-password/employer-forgot-password.component';
import { EmployerRegisterComponent } from '../employer-register/employer-register.component';
import { Router } from '@angular/router';
import { EmployerService } from '../../../../core/services/employer.service';
import { TokenService } from '../../../../core/services/token.service';
import { WhiteSpaceValidator } from '../../../../core/utilities/validation';

@Component({
  selector: 'app-employer-login',
  templateUrl: './employer-login.component.html',
  styleUrls: ['./employer-login.component.scss']
})
export class EmployerLoginComponent implements OnInit {

  // login form model
  loginForm: FormGroup;

  // to hide and show password
  hide = true;

  // to show input error messgae
  submitted = false;

  // show server error
  showServerErrors =  {
    email: '',
    password: ''
  }

  constructor(
    private fb: FormBuilder,
    private commonService: CommonService,
    private appService: AppService,
    private route: Router,
    public dialogRef: MatDialogRef<EmployerLoginComponent>,
    private spinnerService: SpinnerService,
    private employerService: EmployerService,
    private tokenService: TokenService,
  ) { }

  ngOnInit(): void {
    this.loginFormGroup();
    this.loginForm.get('email').valueChanges.subscribe(res => {
      this.showServerErrors ? this.showServerErrors.email = '' : null
    })
    this.loginForm.get('password').valueChanges.subscribe(res => {
      this.showServerErrors ? this.showServerErrors.password = '': null
    })
  }

  loginFormGroup() {
    this.loginForm = this.fb.group({
      email: ['', Validators.compose([Validators.required,  Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$") ])],
      password: ['', Validators.compose([Validators.required, WhiteSpaceValidator.cannotContainSpace])],
      remindMe: [false]
    });
  }

  getPassword() {
    this.loginForm.patchValue({
      remindMe: false
    })
    let savedLogin = localStorage.getItem('logins');
    if (savedLogin) {
      let data = this.commonService.decryptData(savedLogin);
      let obj = data.find(res => res.email == this.loginForm.value.email)
      if (obj) {
        this.loginForm.patchValue({
          password: obj.password,
          remindMe: true
        })
      }
    }
  }

  loginSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const url = this.appService.loginEmployer;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: this.loginForm.value
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if(statusFlag === 'showControlError') {
        this.showServerErrors = res.data;
      } else if(statusFlag) {
        if (this.loginForm.value.remindMe) {
          let savedLogins = [];
          let savedLogin = localStorage.getItem('logins');
          if (savedLogin) {
            let data = this.commonService.decryptData(savedLogin);
            if (data) {
              savedLogins = data;
            }
            savedLogins = savedLogins.filter(el => el.email != this.loginForm.value.email)
            savedLogins.push({ email: this.loginForm.value.email, password: this.loginForm.value.password })
          } else {
            savedLogins = [{ email: this.loginForm.value.email, password: this.loginForm.value.password }]
          }
          localStorage.setItem('logins', this.commonService.encryptData(savedLogins));
        } else {
          let savedLogins = [];
          let savedLogin = localStorage.getItem('logins');
          if (savedLogin) {
            let data = this.commonService.decryptData(savedLogin);
            if (data) {
              savedLogins = data;
            }
            savedLogins = savedLogins.filter(el => el.email != this.loginForm.value.email)
          }
          localStorage.setItem('logins', this.commonService.encryptData(savedLogins));
        }

        this.dialogRef.close();
        if (res && res.hasOwnProperty('data')) {
          if (res.data.hasOwnProperty('accessToken')) {
            localStorage.setItem('loginData', JSON.stringify(res.data));
            this.tokenService.saveToken(res.data.accessToken);
          }
          if (res.data.hasOwnProperty('refreshtoken')) {
            this.tokenService.saveRefreshToken(res.data.refreshtoken);
          }
          if (res.data.hasOwnProperty('userProfilePicPath')) {
            this.employerService.companyLogoPath = res.data.userProfilePicPath;
            this.tokenService.saveRefreshToken(res.data.refreshtoken);
          }
          if (res.data.id === 0) {
            this.employerService.openProfile('createProfile')
          } else {
            this.route.navigateByUrl('/auth/employer/home');
          }
        }      }
    }));
  }



  showForgotPwdModel() {
    this.dialogRef.close();
    this.commonService.openDialog({ template: EmployerForgotPasswordComponent}, (res) => { })
  }

  showRegisterModel() {
    this.dialogRef.close();
    this.commonService.openDialog({ template: EmployerRegisterComponent}, (res) => { })
  }

}
