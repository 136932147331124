import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from '../../../../core/services/app.service';
import { CommonService } from '../../../../core/services/common.service';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { Router } from '@angular/router';
import { SearchJobsService } from '../../../../core/services/search-jobs.service';

@Component({
  selector: 'app-search-jobs-filter',
  templateUrl: './search-jobs-filter.component.html',
  styleUrls: ['./search-jobs-filter.component.scss']
})
export class SearchJobsFilterComponent implements OnInit {

  // form models
  searchJob: FormGroup;

  submitted = false;

  loginData: any;
  
  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    private appService: AppService,
    private route: Router,
    private spinnerService: SpinnerService,
    public searchJobsService: SearchJobsService
  ) { }

  ngOnInit(): void {
    this.searchJobModel();
  }

  searchJobModel() {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.searchJob = this.fb.group({
      jobTitle: ['', Validators.required],
      keySkills: [''],
      location: [''],
      employementType: [''],
      freshness: [''],
      maxiumExperience: [''],
      minimumExperience: [''],
      isVeterans: [false],
      isDifferentlyAbled: [false],
      isFresher: [false],
      isSeniorCitizen: [false],
      remoteOptions: [''],
      salary: [''],
      employeeId: [this.loginData ? this.loginData.id.toString() : 0],
      searchType: ['ADVANCED'],

    });
  }

  setLookingFor(event, key) {
    this.searchJob.patchValue({
      isFresher: event.target.checked && key === 'isFresher' ? true : false,
      isVeterans: event.target.checked && key === 'isVeterans' ? true : false,
      isSeniorCitizen: event.target.checked && key === 'isSeniorCitizen' ? true : false,
    })
    if (event.target.checked && key === 'isFresher') {
      this.searchJob.patchValue({
        maxiumExperience: '',
        minimumExperience: '',
      })
      this.searchJob.controls.maxiumExperience.disable()
      this.searchJob.controls.minimumExperience.disable()
    } else {
      this.searchJob.controls.maxiumExperience.enable()
      this.searchJob.controls.minimumExperience.enable()
    }
  }

  clearValue(key) {
    this.searchJob.patchValue({
      [key]: ''
    })
  }

  setValues(value, key, id?) {
    this.searchJob.patchValue({
      [key]: id ? value[id] : value
    })
  }

  selectMax() {
    this.searchJob.controls['maxiumExperience'].setErrors(null);
    this.searchJob.controls['minimumExperience'].setErrors(null);
    if(!this.searchJob.get('minimumExperience').value) {
      this.searchJob.controls['minimumExperience'].setErrors({'min': true});
    }
    if(this.searchJob.get('maxiumExperience').value && (+this.searchJob.get('minimumExperience').value > +this.searchJob.get('maxiumExperience').value)) {
      this.searchJob.controls['maxiumExperience'].setErrors({'max': true});
    }
  }

  advancedSearchJob() {
    this.submitted = true;
    if(this.searchJob.invalid) {
      return
    }
    this.searchJob.patchValue({
      keySkills: this.searchJob.value.keySkills && this.searchJob.value.keySkills.length ? this.searchJob.value.keySkills.join(',') : ''
    })
    if(this.loginData && this.loginData.id) {
      this.route.navigate(['auth/employee/search-jobs/job-results'], { queryParams:  this.searchJob.value } )
    } else {
      this.route.navigate(['job-search'], { queryParams: this.searchJob.value })
    }
  }

  cancel(){
    this.searchJobModel();
  }

}
