<form class="example-form">
    <mat-form-field class="example-full-width warnning-outlone" [ngClass]="{ 'autocomplete-invalid': invalid }" [floatLabel]="'never'">
      <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto"  [placeholder]="placeholder" (keyup.enter)="add()" (blur)="add()">
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option[text]}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <span *ngIf="!customInput && showNoDataFount()" class="error_text"> No Data Found!!  </span> 
  </form>
  