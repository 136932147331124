<div class="header">
  <div class="container">
    <nav class="navbar navbar-expand-lg menubar p-0">
      <a class="navbar-brand cursor-p logo" (click)="showRoute('home')"
        >JOB Clicks</a
      >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse menu-items mx-3" id="navbarNav">
        <ul class="navbar-nav">
          <li
            class="nav-item cursor-p"
            [ngClass]="{ active: setActiveFlag === 'home' }"
          >
            <a class="nav-link" (click)="showRoute('home')">Home</a>
          </li>
          <li
            class="nav-item cursor-p"
            [ngClass]="{ active: setActiveFlag === 'find-Job' }"
          >
            <a class="nav-link" (click)="showRoute('find-Job')">Find Jobs</a>
          </li>
          <li
            class="nav-item cursor-p"
            [ngClass]="{ active: setActiveFlag === 'blog' }"
          >
            <a class="nav-link" (click)="showRoute('blog')">Blogs</a>
          </li>
          <!-- <li class="nav-item ">
            <a class="nav-link " >Reports</a>
          </li> -->
        </ul>
      </div>
      <div class="d-block text-center">
        <button class="jc-button login-btn" (click)="showLoginRegistor()">
          <i class="fa fa-user mx-1"></i> Login / Register
        </button>
      </div>
    </nav>
  </div>
</div>
