import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { JobclicksAddsComponent } from './components/jobclicks-adds/jobclicks-adds.component';
import { UploadResumeComponent } from './components/upload-resume/upload-resume.component';
import { TableComponent } from './components/table/table.component';
import { SharedImportModule } from '../shared-import';
import { YesOrNoComponent } from './components/yes-or-no/yes-or-no.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { AutocompleteChipComponent } from './components/autocomplete-chip/autocomplete-chip.component';
import { AddsComponent } from './components/adds/adds.component';
import { ReminderPopupComponent } from './components/reminder-popup/reminder-popup.component';
// import { HttpClientModule } from '@angular/common/http'
// import { CarouselModule } from 'ngx-owl-carousel-o';

import { PhonePipe } from './pipe/phone.pipe';
import { SearchJobDetailsComponent } from './components/searchJob/search-job-details/search-job-details.component';
import { RecommendedJobsComponent } from './components/searchJob/recommended-jobs/recommended-jobs.component';
import { SearchJobsResultsComponent } from './components/searchJob/search-jobs-results/search-jobs-results.component';
import { SearchJobCardComponent } from './components/searchJob/search-job-card/search-job-card.component';
import { CommonTablePopupComponent } from './components/common-table-popup/common-table-popup.component';
import { CommonJobAlertComponent } from './components/common-job-alert/common-job-alert.component';
import { SearchJobsFilterComponent } from './components/searchJob/search-jobs-filter/search-jobs-filter.component';
import { SendMailComponent } from './components/send-mail/send-mail.component';
import { BlogsComponent } from './components/blogs/blogs.component';

@NgModule({
  declarations: [FileUploadComponent, JobclicksAddsComponent, UploadResumeComponent, TableComponent,
    YesOrNoComponent, ToasterComponent, AutocompleteComponent, AutocompleteChipComponent, AddsComponent,
    ReminderPopupComponent, PhonePipe, SearchJobDetailsComponent, RecommendedJobsComponent, BlogsComponent,
    SearchJobsResultsComponent, SearchJobCardComponent, CommonTablePopupComponent, CommonJobAlertComponent, SearchJobsFilterComponent, SendMailComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedImportModule
    // CarouselModule
  ],
  exports: [FormsModule, ReactiveFormsModule, FileUploadComponent, JobclicksAddsComponent,
    UploadResumeComponent, TableComponent, YesOrNoComponent, ToasterComponent, AutocompleteComponent,
    AutocompleteChipComponent, AddsComponent, ReminderPopupComponent, PhonePipe, SearchJobDetailsComponent, BlogsComponent,
    RecommendedJobsComponent, SearchJobsResultsComponent, SearchJobCardComponent, CommonTablePopupComponent, CommonJobAlertComponent, SearchJobsFilterComponent// CarouselModule
  ],
  providers: [DatePipe]

})
export class SharedModule { }
