import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpMethod } from '../../../../core/enums/http-handlers';
import { AppService } from '../../../../core/services/app.service';
import { CommonService } from '../../../../core/services/common.service';
import { SpinnerService } from '../../../../core/services/spinner.service';

@Component({
  selector: 'app-search-job-card',
  templateUrl: './search-job-card.component.html',
  styleUrls: ['./search-job-card.component.scss']
})
export class SearchJobCardComponent implements OnInit {

  @Input() list = [];

  p = 1;
  @Input() set selectedPage(value) {
    this.p = value
  }

  @Input() pageSize = 10;
  @Input() totalNoOfjobs: number;
  @Input() noOfPages: any;
  @Input() jobTitle: string;

  @Input() showApply = false;
  @Input() showApplied = false;
  @Input() showDelete = false;
  isShown: any;
  @Output() updateRoute = new EventEmitter()
  @Output() resultsPerPage = new EventEmitter()
  @Output() updateList = new EventEmitter()
  @Output() shareEmit = new EventEmitter()
  loginData: any;
  constructor(
    public appService: AppService,
    public commonService: CommonService,
    private route: Router,
    private router: ActivatedRoute,
    private http: HttpClient,
    private spinnerService: SpinnerService
  ) {
   this.loginData = JSON.parse(localStorage.getItem('loginData'))
   }

  ngOnInit(): void {
  }

  handlePageChange(event) {
    const obj = { resultsPerPage: this.pageSize, pageNumber: event }
    this.resultsPerPage.emit(obj)
  }

  changePagePerIndex(i) {
    this.pageSize = i
    this.p = 1
    const obj = { resultsPerPage: this.pageSize, pageNumber: 1 }
    this.resultsPerPage.emit(obj)
  }

  showAppliedDetails(job) {
    if(job.jobApplyed) {
      return;
    }
    this.showDetails(job);
  }

  showSaveDetails(job) {
    if(job.jobSaved) {
      return;
    }
    const apiObj = {
      url: `${this.appService.saveEmployeeJobs}`,
      methodType: HttpMethod.POST,
      requestObj: {  employeeId: this.loginData.id , jobId: job.jobId }
    }
    this.commonService.commonApiCall(apiObj,(res, statusFlag) => {
      this.spinnerService.hide();
      if(statusFlag) {
        job.jobSaved = true;
      }
    });
  }

  showDetails(job) {
    this.updateRoute.emit();
    if(this.loginData && this.loginData.id) {
      this.route.navigate(['auth/employee/search-jobs/job-details', job.jobId], { queryParams:  this.router.queryParams['_value'] });
    } else {
      this.route.navigate(['job-search-details', job.jobId], { queryParams:  this.router.queryParams['_value'] });
    }
  }


  pageHeader() {
    return  `${(((+this.p * +this.pageSize) - this.pageSize) + 1)} - 
    ${(+this.p * +this.pageSize) < this.totalNoOfjobs ? (+this.p * +this.pageSize) : this.totalNoOfjobs}`
  }

  deleteJob(job) {
    const url = `${this.appService.deleteEmployeeSavedJobs}?employeeId=${this.loginData.id}&employeeJobId=${job.employeeJobID}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.DELETE
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if(statusFlag) {
        this.updateList.emit();
      }
    }));
  }

  selectShare(val, job) {
    this.shareEmit.emit({ val: val, job: job});
    return;
    if(val == 'email') {
      window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${[job.email]}`, '_blank');
    } else if(val == 'copy') {
      this.commonService.copyText(job.email);
      this.commonService.openSnackBar('Email Copied Successfully', 'toaster-success');
    } else if(val == 'whatsApp') {
      // <a href="https://web.whatsapp.com/send?text=www.google.com" data-text="Take a look at this awesome website:" class="wa_btn wa_btn_s" style="display:none">Share1</a>
      window.open(`https://web.whatsapp.com/send?text=${[job.description]}`, '_blank');
    } else {
    //   const myPost:any = {
    //     "author": "urn:li:person:9a850311a",
    //     "lifecycleState": "PUBLISHED",
    //     "specificContent": {
    //         "com.linkedin.ugc.ShareContent": {
    //             "shareCommentary": {
    //                 "text": "Learning more about LinkedIn by reading the LinkedIn Blog!"
    //             },
    //             "shareMediaCategory": "ARTICLE",
    //             "media": [
    //                 {
    //                     "status": "READY",
    //                     "description": {
    //                         "text": "Official LinkedIn Blog - Your source for insights and information about LinkedIn."
    //                     },
    //                     "originalUrl": "www.linkedin.com/in/varun-kumar-9a850311a",
    //                     "title": {
    //                         "text": "Official LinkedIn Blog"
    //                     }
    //                 }
    //             ]
    //         }
    //     },
    //     "visibility": {
    //         "com.linkedin.ugc.MemberNetworkVisibility": "PUBLIC"
    //     }
    // }
    //   const header: any = {
    //     'Access-Control-Allow-Origin': '*',
    //     'Content-Type': 'application/json',
    //     'X-Restli-Protocol-Version': '2.0.0',
    //     'Authorization': 'Bearer ' + 'AQVf6ZLhT32E3D_r1lwsqRL1gAU4qxhg6IFd-Cy-y9YJP3ZGR_NXjN9ZFuVXfmPlAVT1PNT_qqydkVVUfRuereyihTuMnGnQ0f7Ra9g2W4j-cUCPcCzrsoqFd-47sGYIuHOwwMW-kKLjtUofhfY4pFfq5HUNCIDeHPCYgqRddPpiUf1K_YaHTA_j1QirktdG4bwCzXtgrjYzeSTqrRpQugNkWwL-arY8PJUCOFY9R27GSRokIs2yMDJniDaMJ7ciDq3PoiL7NjZL8AJ6eq2Cng2trNB7PMElzPJI2S7r0stum4FkteAV-741wAAT-ta4BAGAsQwBrdvG57LTT1Oh58D2-sZQtQ'
    //   };
    //   this.http.post('https://api.linkedin.com/v2/ugcPosts', myPost, header).subscribe(res => {
    //     alert(JSON.stringify(res));
    //   });

      const header1: any = {
        'Access-Control-Allow-Origin': "http://localhost:4200",
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + 'AQVf6ZLhT32E3D_r1lwsqRL1gAU4qxhg6IFd-Cy-y9YJP3ZGR_NXjN9ZFuVXfmPlAVT1PNT_qqydkVVUfRuereyihTuMnGnQ0f7Ra9g2W4j-cUCPcCzrsoqFd-47sGYIuHOwwMW-kKLjtUofhfY4pFfq5HUNCIDeHPCYgqRddPpiUf1K_YaHTA_j1QirktdG4bwCzXtgrjYzeSTqrRpQugNkWwL-arY8PJUCOFY9R27GSRokIs2yMDJniDaMJ7ciDq3PoiL7NjZL8AJ6eq2Cng2trNB7PMElzPJI2S7r0stum4FkteAV-741wAAT-ta4BAGAsQwBrdvG57LTT1Oh58D2-sZQtQ'

      };
      const myPost1 = {
        grant_type: 'client_credentials',
        client_id: '77lkknd7qe6r0u',
        client_secret: '1yjEvI82cN1mS8f3'
      }
      this.http.post('https://www.linkedin.com/oauth/v2/accessToken', myPost1, header1).subscribe(res => {
        
      });
      // this.http.get('http://localhost:3000/hello').subscribe(res => {
        
      // });
    }
  }

  toggleShow(id) {
    if(this.isShown == id) {
      this.isShown = null;
      return
    }
    this.isShown = id;
    }

    clearToggle(){
      setTimeout(() => {
      this.isShown = null;
      }, 100);
    }

}
