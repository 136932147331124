import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpMethod } from '../../../core/enums/http-handlers';
import { AppService } from '../../../core/services/app.service';
import { CommonService } from '../../../core/services/common.service';
import { SpinnerService } from '../../../core/services/spinner.service';

@Component({
  selector: 'app-send-mail',
  templateUrl: './send-mail.component.html',
  styleUrls: ['./send-mail.component.scss']
})
export class SendMailComponent implements OnInit {

  @ViewChild('mailData', { static: false }) mailData: ElementRef;

  submitted = false;
  disabled = false;
  editorModules = {
    toolbar: {
      container: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block"],
        [{ header: 1 }, { header: 2 }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ direction: "rtl" }],
        [{ size: ["small", false, "large", "huge"] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ["clean"],
        ["link", "image", "video"],
        ["Resize", "DisplaySize", "Toolbar"],
        ['emoji'],
      ],
    }
    // autoLink: true,
  };

  mailModelForm: FormGroup;
  selectedArr = [];
  getAllJobTitlesList = [];

  constructor(
    private router: ActivatedRoute,
    private fb: FormBuilder,
    private appService: AppService,
    private commonService: CommonService,
    private spinnerService: SpinnerService,
    private cdr: ChangeDetectorRef
  ) {
    this.router.queryParams.subscribe(res => {
      if (res.key) {
        this.selectedArr = JSON.parse(res.key);
        if (this.selectedArr[0].type == 'employee') {
          this.mailEmpModel();
          this.mailModelForm.patchValue({
            type: this.selectedArr[0].type,
            Email: this.selectedArr[0].name ? this.selectedArr.map(el => el.name) : '',
            Subject: this.selectedArr[0].jobTitle ? this.selectedArr[0].jobTitle + (this.selectedArr[0].location ?  ' - ' + this.selectedArr[0].location : '') : ''
          })
          this.paste();
        } else {
          this.mailModel();
          this.mailModelForm.patchValue({
            type: this.selectedArr[0].type,
            EmployeeIds: this.selectedArr.some((s: any) => s.id) ? this.selectedArr.map(el => el.id) : '',
            names: this.selectedArr.some((s: any) => s.name) ? this.selectedArr.map(el => el.name) : '',
            jobTitle: this.selectedArr.some((s: any) => s.jobTitle) ? this.selectedArr.map(el => el.jobTitle) : '',
            Subject: this.selectedArr.some((s: any) => s.jobTitle) ? this.selectedArr.map(el => el.jobTitle + (el.location ? ' - ' + el.location : '')).join(',') : ''
          })
        }
      }
    })
  }

  mailModel() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.mailModelForm = this.fb.group({
      type: [''],
      names: ['', Validators.required],
      Subject: ['', Validators.required],
      Message: ['', Validators.required],
      EmployeeIds: ['', Validators.required],
      jobTitle: ['', Validators.required],
      companyName: [loginData.companyName.toString(), Validators.required],
      EmployerID: [loginData.id.toString()]
    });
  }

  mailEmpModel() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.mailModelForm = this.fb.group({
      type: [''],
      Subject: ['', Validators.required],
      Message: ['', Validators.required],
      EmployeeIds: [[loginData.id]],
      Email: [loginData.id.toString()]
    });
  }

  paste() {
    const copyData = JSON.parse(localStorage.getItem('copyData'));
    this.mailModelForm.patchValue({
      Message: copyData
    })
  }

  emails(emails) {
    
    if(emails[emails.length-1]) {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
      if(regex.test(emails[emails.length-1])) {
        this.mailModelForm.patchValue({
          Email: emails
        })
      } else {
        emails.pop();
        this.mailModelForm.patchValue({
          Email: emails
        })
        this.commonService.openSnackBar('Please provide valid emailId', 'toaster-success');
      }
    }
  }


  ngOnInit(): void {
    this.getAllJobTitles();
  }

  clearValue(key) {
    this.mailModelForm.patchValue({
      [key]: ''
    })
  }

  setValues(value, key, id?) {
    this.mailModelForm.patchValue({
      [key]: id ? value[id] : value
    })
  }

  getAllJobTitles() {
    const url = `${this.appService.getAllJobTitles}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      if (statusFlag && res && res.hasOwnProperty('data')) {
        this.getAllJobTitlesList = res.data;
      }
    }));
  }

  removeEmp(id) {
    this.selectedArr = this.selectedArr.filter(res => res.id != id);
    this.mailModelForm.patchValue({
      EmployeeIds: this.selectedArr.map(el => el.id)
    })
  }

  submitForm() {
    this.submitted = true;
    if (this.mailModelForm.invalid) {
      return;
    }
    if(this.mailModelForm.value.type == 'employee') {
      this.sendJobInformationToEmails();
      return
    }
    const url = `${this.appService.sharejobstoApplicants}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: {
        "EmployerID": this.mailModelForm.value.EmployerID,
        "Subject": (this.mailModelForm.value.Subject && Array.isArray(this.mailModelForm.value.Subject)) ? this.mailModelForm.value.Subject.join(',') : this.mailModelForm.value.Subject,
        "Message": this.mailModelForm.value.Message,
        "EmployeeIds": this.mailModelForm.value.EmployeeIds
     } 
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag) {
        this.disabled = true;
        this.commonService.openSnackBar('Mail sent Succsesfully...', 'toaster-success');
        setTimeout(() => {
          this.closeForm();
        }, 5000);
      }
    }))
  }

  sendJobInformationToEmails() {
    const url = `${this.appService.sendJobInformationToEmails}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: {
        "Email": this.mailModelForm.value.Email.join(','),
        "Subject": this.mailModelForm.value.Subject,
        "Message" : this.mailModelForm.value.Message,
         "EmployeeIds": this.mailModelForm.value.EmployeeIds
       }
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag) {
        this.disabled = true;
        this.commonService.openSnackBar('Mail sent Succsesfully...', 'toaster-success');
        setTimeout(() => {
          this.closeForm();
        }, 5000);
      }
    }))
  }

  closeForm() {
    window.close()
  }

}
