import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdvancedSearchComponent } from './block/advanced-search/advanced-search.component';
import { BlogComponent } from './block/blog/blog.component';
import { HomeComponent } from './block/home/home.component';
import { SearchBasicDetailsComponent } from './block/search-basic-details/search-basic-details.component';
import { SearchBasicSearchComponent } from './block/search-basic-search/search-basic-search.component';
import { SetPasswordComponent } from './block/user-management/employer/set-password/set-password.component';
import { SearchJobsFilterComponent } from './shared/components/searchJob/search-jobs-filter/search-jobs-filter.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
   },
   {
     path: 'blog',
     component: BlogComponent
   },
   {
     path: 'blog/:id',
     component: BlogComponent
   },
   {
     path: 'home',
     component: HomeComponent
   },
   {
     path: 'set-password',
     component: SetPasswordComponent
   },
   {
     path: 'job-search',
     component: SearchBasicSearchComponent
   },
   {
     path: 'job-search-details/:id',
     component: SearchBasicDetailsComponent
   },
   {
     path: 'find-Job',
     component: AdvancedSearchComponent
   },
  {
    path: 'auth',
    loadChildren: () =>
      import('./feature/feature.module').then((m) => m.FeatureModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
