
    <div class="container-fluid tech-news p-0 p-relative">
        <span class="close-button" (click)="dialogRef.close()"><img src="assets/images/close.png" class="close-popup" alt="logo"></span>
            <div class="row">
                <div class="col p-0">
                    <div class="landing-login-page">
                        <div class="logo text-center">
                            <img src="assets/images/logo.png" class="mb-3" alt="...">
                            <p>The search for your ideal job has come to an end! There are many different career paths to choose from. Look for a job that is a perfect fit for you.</p>
                        </div>
                        <div class="login-title text-center">
                            Login/Register
                        </div>
                        <div class="forms mt-3">
                            <div class="login-button mt-3 text-center">
                                <button class="employer m-2" (click)="loginJobSeeker()">Applicant</button>
                                <button class="employer m-2" (click)="loginEmployer()">Employer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </div>
