import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { HttpMethod } from '../../../../core/enums/http-handlers';
import { AppService } from '../../../../core/services/app.service';
import { CommonService } from '../../../../core/services/common.service';
import { SpinnerService } from '../../../../core/services/spinner.service';
import { DomSanitizer } from '@angular/platform-browser';
import { YesOrNoComponent } from '../../../../shared/components/yes-or-no/yes-or-no.component';
import { AlertInfo } from '../../../../core/enums/alert-info';
import { LoginComponent } from '../../../../block/user-management/jobSeeker/login/login.component';
import { SearchJobsService } from '../../../../core/services/search-jobs.service';
import { RegisterComponent } from '../../../../block/user-management/jobSeeker/register/register.component';
import { EmployeeService } from '../../../../core/services/employee.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-search-job-details',
  templateUrl: './search-job-details.component.html',
  styleUrls: ['./search-job-details.component.scss']
})
export class SearchJobDetailsComponent implements OnInit, OnDestroy {

  getJobDetailsbyJobIdData: any;
  loginData: any;

  // form models
  searchJob: FormGroup;

  // to show input error messgae
  submitted = false;
  isShown: any;


  constructor(
    public commonService: CommonService,
    public appService: AppService,
    private spinnerService: SpinnerService,
    private router: ActivatedRoute,
    private route: Router,
    public searchJobsService: SearchJobsService,
    private employeeService: EmployeeService,
    public sanitizer: DomSanitizer,
    private fb: FormBuilder,
  ) {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    this.router.params.subscribe(res => {
      if (res.hasOwnProperty('id') && res.id) {
        this.getJobDetailsbyJobId(res.id)
      }
    })
    this.router.queryParams.subscribe(res => {
      if (res.hasOwnProperty('type') && res.type == 'register') {
        this.commonService.openDialog({ template: RegisterComponent }, (res) => { })
      }
    })
    this.searchJobsService.getJobSearchDropdownData();
  }

  ngOnInit(): void {
    this.searchJobModel();
  }


  onLinkClickEmit(event) {
    this.route.navigate(['auth/employer/search-candidates-jobs/job-candidates-results', event.jobId, event.jobTitle])
  }


  searchJobModel() {
    this.searchJob = this.fb.group({
      jobTitle: ['', Validators.required],
      location: [''],
      searchType: ['BASIC'],
      findJob: true
    });
  }

  clearValue(key) {
    this.searchJob.patchValue({
      [key]: ''
    })
  }

  setValues(value, key, id?) {
    this.searchJob.patchValue({
      [key]: id ? value[id] : value
    })
  }

  basicSearchJob() {
    this.submitted = true;
    if (this.searchJob.invalid) {
      return
    }
    this.route.navigate(['job-search'], { queryParams: this.searchJob.value })
  }

  ngOnDestroy() {
  }

  getJobDetailsbyJobId(id) {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    let url;
    if (this.loginData) {
      url = `${this.appService.getJobDetailsbyJobId}?JobID=${id}&employeeID=${this.loginData.id}`;
    } else {
      url = `${this.appService.getJobDetailsbyJobId}?JobID=${id}`;
    }
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res.data) {
        this.getJobDetailsbyJobIdData = res.data;
        this.employeeService.getNavigationCount();
      }
    });
  }

  register() {
    window.open(`${this.route.url}&type=register`, '_blank');

  }

  login() {
    this.commonService.openDialog({ template: LoginComponent }, (res) => { })
  }


  applyJobsinEmployee(job) {
    if (job.jobApplyed) {
      return;
    }
    const url = `${this.appService.applyJobsinEmployee}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: {
        employeeId: this.loginData.id,
        jobId: job.jobId,
        actionType: 1
      }
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res.data) {
        if (res.data && res.data.hasOwnProperty('applyed') && res.data.applyed) {
          this.commonService.snackBar('Successfully Applied Job', AlertInfo.SUCCESS);
          this.getJobDetailsbyJobId(job.jobId)
        } else if (res.data) {
          this.applyJobModel(res.data, job)
        }
      }
    });
  }

  saveJobsinEmployee(job) {
    if (job.jobSaved) {
      return;
    }
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const url = `${this.appService.saveEmployeeJobs}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: { employeeId: loginData.id, jobId: job.jobId }
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag) {
        job.jobSaved = true;
      }
    });
  }


  applyJobModel(data, job) {
    const obj = {
      template: YesOrNoComponent,
      data: {
        header: 'Make sure!',
        description: `Your <span class='text-jc'>${data}</span> fields are not filled.`,
        yes: data ? 'Skip & Apply Job' : 'Apply Job',
        no: data ? 'Edit Profile' : '',
        buttonClass: 'actie-btn',
        showNoButton: !data ? true : false
      }
    }
    this.commonService.openDialog(obj, (res) => {
      if (res) {
        this.applyJob(job)
      } else if (res === false) {
        this.route.navigateByUrl('auth/employee/edit-myprofile');
      }
    })
  }

  applyJob(job) {
    const url = `${this.appService.skipAndApplyPosition}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: {
        employeeId: this.loginData.id,
        jobId: job.jobId,
        actionType: 1
      }
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res.data && res.data.applyed) {
        this.commonService.snackBar('Successfully Applied Job', AlertInfo.SUCCESS);
        this.getJobDetailsbyJobId(job.jobId)
      }
    });
  }

  selectShare(event, job) {
    const newJobs = job.jobs[0];
    newJobs.companyName = job.companyName
    newJobs.employeementTypeName = newJobs.employementType
    this.commonService.shareEmit({ val: event, job: newJobs });
  }

  toggleShow(id) {
    if (this.isShown == id) {
      this.isShown = null;
      return
    }
    this.isShown = id;
  }

  clearToggle(){
    setTimeout(() => {
    this.isShown = null;
    }, 100);
  }

}
