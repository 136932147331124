import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppLayoutComponent } from './app-layout/app-layout.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { RegisterComponent } from './user-management/jobSeeker/register/register.component';
import { LoginComponent } from './user-management/jobSeeker/login/login.component';
import { ForgotPasswordComponent } from './user-management/jobSeeker/forgot-password/forgot-password.component';
import { EmployerLoginComponent } from './user-management/employer/employer-login/employer-login.component';
import { EmployerRegisterComponent } from './user-management/employer/employer-register/employer-register.component';
import { EmployerForgotPasswordComponent } from './user-management/employer/employer-forgot-password/employer-forgot-password.component';

import { SharedImportModule } from '../shared-import';
import { SharedModule } from '../shared/shared.module';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { SetPasswordComponent } from './user-management/employer/set-password/set-password.component';
import { SearchBasicSearchComponent } from './search-basic-search/search-basic-search.component';
import { SearchBasicDetailsComponent } from './search-basic-details/search-basic-details.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';

@NgModule({
  declarations: [
    AppLayoutComponent,
    UserManagementComponent,
    FooterComponent,
    NavigationComponent,
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    EmployerLoginComponent,
    EmployerRegisterComponent,
    EmployerForgotPasswordComponent,
    HomeComponent,
    BlogComponent,
    SetPasswordComponent,
    SearchBasicSearchComponent,
    SearchBasicDetailsComponent,
    AdvancedSearchComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedImportModule,
    SharedModule
  ],
  exports:[
    AppLayoutComponent,
    UserManagementComponent,
    FooterComponent,
    NavigationComponent,
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent
  ]
})
export class BlockModule { }
