import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { LoginComponent } from '../user-management/jobSeeker/login/login.component';
import { RegisterComponent } from '../user-management/jobSeeker/register/register.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router, private commonService: CommonService) { }

  ngOnInit(): void {
  }

  route(route) {
    this.router.navigateByUrl(route)
  }

  showRegisterModel() {
    this.commonService.openDialog({ template: RegisterComponent }, (res) => { })
  }

  loginJobSeeker() {
    this.commonService.openDialog({ template: LoginComponent}, (res) => { }) 
  }


}
