import { Injectable } from '@angular/core';
import { HttpMethod } from '../enums/http-handlers';
import { checkNullOrUndefined } from '../utilities/nullOrUndefined';
import { AlertInfo } from '../enums/alert-info';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { ApiService } from './api.service';
import { SpinnerService } from './spinner.service';
import { ToasterComponent } from '../../shared/components/toaster/toaster.component';
import { Router } from '@angular/router';
import { YesOrNoComponent } from '../../shared/components/yes-or-no/yes-or-no.component';
import * as CryptoJS from 'crypto-js';
import { Clipboard } from '@angular/cdk/clipboard';
import { PerviousRouteService } from '../../shared/pervious-route.service';

@Injectable({
  providedIn: 'root'
})

export class CommonService {

  dialogRef: any;

  constructor(
    // private apiService: ApiService,
    // private alertService: AlertService,
    public dialog: MatDialog,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private _snackBar: MatSnackBar,
    public clipboard: Clipboard,
    private router: Router,
    private perviousRouteService: PerviousRouteService
  ) { }

  openDialog(obj, callBack) {
    // const dialogRef = this.dialog.open(template, {
    //   disableClose: true,
    //   width: width ? width : '500px',
    //   height: 'auto',
    //   data: data ? data : null
    // });

    this.dialogRef = this.dialog.open(obj?.template, {
      disableClose: true,
      width: obj?.width ? obj?.width : '500px',
      height: 'auto',
      data: obj.data ? obj.data : null,
      panelClass: obj.customClass ? obj.customClass : ''
    });

    this.dialogRef.afterClosed().subscribe(result => {
      callBack(result)
    });
  }

  hideDialog() {
    if (this.dialogRef) {
      this.dialogRef.close()
    }
  }

  snackBar(message, type) {
    const obj = {
      message: message,
      type: type
    }
    this._snackBar.openFromComponent(ToasterComponent, {
      duration: 1000,
      data: obj,
      panelClass: 'toster-error'
    });
  }

  horizontalPosition: MatSnackBarHorizontalPosition = 'end';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  openSnackBar(message, className) {
    this._snackBar.open(message, '', {
      duration: 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: [className]
    });
  }


  apiHandler(methodType, url, requestObj) {

    switch (methodType) {
      case HttpMethod.POST:
        return this.apiService.commonPostHandler(url, requestObj);
      case HttpMethod.PUT:
        return this.apiService.commonPutHandler(url, requestObj);
      case HttpMethod.DELETE:
        return this.apiService.commonDeleteHandler(url);
      case HttpMethod.GET:
        return this.apiService.commonGetHandler(url);
      case HttpMethod.GETWITHOUTHEADERS:
        return this.apiService.commonGetByWithoutHandler(url, requestObj);
    }
  }

  // common Post Api need to use in all screens
  commonApiCall(obj, callBack) {
    const requestObj = obj.requestObj || null;
    // obj.showPopup = obj.hasOwnProperty('showPopup') ? obj.showPopup : false;
    obj.showSpinner = obj.hasOwnProperty('showSpinner') ? obj.showPopup : true;
    if (obj.showSpinner) {
      this.spinnerService.show();
    }
    this.apiHandler(obj.methodType, obj.url, requestObj).pipe(take(1)).subscribe((res) => {
      if (res.statusCode === 1) {
        if (obj.methodType == HttpMethod.POST || obj.methodType == HttpMethod.PUT || obj.methodType == HttpMethod.DELETE) {
          this.openSnackBar(res.message, 'toaster-success')
        }
        callBack(res, true);
        // if(obj.showPopup) {
        //   this.getMessages(res, AlertInfo.SUCCESS, obj.methodType);
        // }
      } else if (res.statusCode === 2) {
        if (obj.methodType == HttpMethod.GET && obj.showGetError) {
          callBack(res, 'status2GetError');
          this.openSnackBar(res.message, 'toaster-validation')
        }
        if (obj.methodType == HttpMethod.POST || obj.methodType == HttpMethod.PUT || obj.methodType == HttpMethod.DELETE) {
          this.openSnackBar(res.message, 'toaster-validation')
        }
        this.spinnerService.hide();
      } else if (res.statusCode === 3) {
        if (obj.methodType == HttpMethod.POST || obj.methodType == HttpMethod.PUT || obj.methodType == HttpMethod.DELETE) {
          this.openSnackBar(res.message, 'toaster-error')
        }
        this.spinnerService.hide();
      } else if (res.statusCode === 4) {
        callBack(res, 'showControlError');
      } else {
        this.spinnerService.hide();
        callBack(res, false);
        this.getMessages(res, AlertInfo.ERROR, obj.methodType);
      }
    }, (error) => {
      this.spinnerService.hide();
      this.snackBar(error, AlertInfo.ERROR);
    }
    );
  }

  private getMessages(res: any, type, methodType) {
    if (methodType == HttpMethod.GET && type == AlertInfo.SUCCESS) {
      return;
    }
    if (
      !checkNullOrUndefined(res.message) &&
      res.message != ''
    ) {
      this.snackBar(res.message, type);
    }
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  checkNullOrUndefined(val) {
    if (val === null || val === undefined) {
      return true;
    } else {
      return false;
    }
  }

  clearStorage() {
    this.hideDialog();
    localStorage.removeItem('refreshtoken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('loginData');
  }

  confirmationPopup(val, callBack) {
    const obj = {
      template: YesOrNoComponent,
      data: val
    }
    this.openDialog(obj, (res) => {
      callBack(res)
    })
  }

  formatDate(date = new Date(), m?) {
    var d = new Date(date),
      month = '' + (d.getMonth() + (m ? 0 : 1)),
      day = '' + d.getDate(),
      year = '' + d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  formatTime(date) {
    if (date) {
      return new Date(date).toTimeString().split(' ')[0]
    }
  }

  getYears(startYear, year?) {
    var currentYear = year ? year + new Date().getFullYear() : new Date().getFullYear();
    var years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.unshift(startYear++);
    }
    return years;
  }

  counter(i: number) {
    return new Array(i);
  }

  getFileExtension(file) {
    return file && file.split('.').pop();
  }

  getFileName(file) {
    return file && file.split('/').pop();
  }

  convertHtmlToString(str) {
    return str.replace(/(<([^>]+)>)/gi, "");
  }



  getTimeDifference(time) {

    let diffInMilliSeconds = Math.abs(new Date().getTime() - new Date(time).getTime()) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    if (days > 0) {
      return `${days} days`;
    }
    diffInMilliSeconds -= days * 86400;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    if (hours > 0) {
      return `${hours} hours`;
    }
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    if (minutes > 0) {
      return `${minutes} minutes`;
    }
    diffInMilliSeconds -= minutes * 60;

    // calculate sec
    const sec = Math.floor(diffInMilliSeconds / 60) % 60;
    return `Just now`;

    // let difference = '';
    // if (days > 0) {
    //   difference += (days === 1) ? `${days} day, ` : `${days} days, `;
    // }

    // difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

    // difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`; 
  }

  getToken(): any {
    const token = this.router.routerState.snapshot.url.includes('employer/') ? localStorage.getItem('accessToken') : localStorage.getItem('accessToken')
    return 'Authorization:Bearer ' + token;
  }

  setToken(newToken): any {
    this.router.routerState.snapshot.url.includes('employer/') ? localStorage.setItem('accessToken', newToken) : localStorage.setItem('accessToken', newToken)
  }


  setRefreshToken(newToken): any {
    this.router.routerState.snapshot.url.includes('employer/') ? localStorage.setItem('refreshtoken', newToken) : localStorage.setItem('refreshtoken', newToken)
  }

  loginRoute() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData) {
      if (loginData.roleName == 'employee') {
        this.router.navigateByUrl('/auth/employee/home');
      } else {
        this.router.navigateByUrl('/auth/employer/home');
      }
    }
  }

  encryptData(data) {
    const password = 'Mypassword123';
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), password).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {
    const password = 'Mypassword123';
    try {
      const bytes = CryptoJS.AES.decrypt(data, password);
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }


  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
  }

  shareEmit(event) {
    if (event.val == 'email') {
      let arr = [];
      arr.push({
        type: 'employee',
        name: '',
        jobTitle: event.job.jobTitle,
        location: event.job.location
      })
      if (arr.length) {
        this.copyTextData(event.job);
        window.open('/auth/employee/sendMail?key=' + JSON.stringify(arr), '_blank');
      }
    } else if (event.val == 'copy') {
      this.copyTextData(event.job);
      this.openSnackBar('Copied Successfully', 'toaster-success');
    } else if (event.val == 'copyLink') {
      this.copyText(`${window.location.origin}/job-search-details/${event.job.jobId}`);
      this.openSnackBar('Link Copied Successfully', 'toaster-success');
    } else if (event.val == 'whatsApp') {
      window.open(`https://web.whatsapp.com/send?text=${[`${window.location.origin}/job-search-details/${event.job.jobId}`]}`, '_blank');
      // window.open(`https://web.whatsapp.com/send?text=${[`https://www.youtube.com/watch?v=ohpCMpderow`]}`, '_blank');  
      // console.log(`https://web.whatsapp.com/send?text=${[`${window.location.origin}/auth/employee/search-jobs/job-details/${event.job.jobId}`]}`);
      // console.log(`https://web.whatsapp.com/send?text=${[`https://www.youtube.com/watch?v=ohpCMpderow`]}`);
      // window.open('whatsapp://send?text= https://www.youtube.com/watch?v=ohpCMpderow');  // For mobile WhatsApp

    } else if (event.val == 'linkedin') {

      //   curl --location --request POST 'https://www.linkedin.com/oauth/v2/introspectToken' \
      // --header 'Content-Type: application/x-www-form-urlencoded' \
      // --data-urlencode 'token=AQURQlfV7SwlwrotnNNvPOajrI9djnOHoOmaC6vldVSzn-7NFchPNR_wzeqgl_MYRgCyzUs6W1khT-VT3H9dTWYzcPGTF2NUgNqVCZW7ArpozilgtszXqE_QPvcgkvmFl40CaLs_2i2MjktV5xhc9ed-hP-jDIFNr2Pso83tbPHLbBzFmt-NJUe4liSmkMr6bbs0Iw6S1oQZoh5gFYDqgveUHJfUXqkT7SOkADpTfT4-tUpf9KHkRZTBxbG3BKC_YsED4rFgEnCdjhoYD6NKh6Jem5g9aIhbEqDsO_nrD8vuIPLVbTapQ3-WaQc8-u_Bj23ruoD_t_rKoMrEUTP9VOIinaFdxQ' \
      // --data-urlencode 'client_id=77lkknd7qe6r0u' \
      // --data-urlencode 'client_secret=1yjEvI82cN1mS8f3'

      //   var $requestUrl = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=868z7fx6fe0mgb&redirect_uri=https://oauth.pstmn.io/v1/callback&state=1111&scope=r_liteprofile%20";
      //   window.open($requestUrl, "_blank", "location=no,menubar=0,toolbar=0,titlebar=0,width=500,height=550,left=500,top=100");
    }
  }

  copyTextData(job) {
    let copyText = '<div>';
    copyText = `${copyText} <b> Job Title </b> <span>${job.jobTitle || ''} </span></br>`;
    copyText = `${copyText} <b> Company Name </b> <span>${job.companyName || ''} </span></br>`;
    if (job.clientName) {
      copyText = `${copyText} <b> Client Name </b> <span>${job.clientName || ''} </span></br>`;
    }
    copyText = `${copyText} <b> Employement Type </b> <span>${job.employeementTypeName || ''} </span></br>`;
    copyText = `${copyText} <b> Location </b> <span>${job.location || ''} </span></br>`;
    copyText = `${copyText} <b> Min Experience </b> <span>${job.minExperience || ''} </span></br>`;
    copyText = `${copyText} <b> Max Experience </b> <span>${job.maxExperience || ''} </span></br>`;
    copyText = `${copyText} <b> Education </b> <span>${job.education || ''} </span></br>`;
    copyText = `${copyText} <b> RemoteOption </b> <span>${job.remoteOption || ''} </span></br>`;
    copyText = `${copyText} <b> Key Skills </b> <span>${job.keySkills || ''} </span></br>`;
    // copyText = `${copyText} <b> Job Applications Count </b> <span>${job.jobApplicationsCount || ''} </span></br>`;
    copyText = `${copyText} <b> Job Vacancies </b> <span>${job.jobVacancies || ''} </span></br>`;
    copyText = `${copyText} <b> Job Information </b> <span>${job.description || ''} </span></br>`;
    copyText = `${copyText} <b> Roles and Responsibilities </b> <span>${job.rolesAndResponsibilities || ''} </span></br>`;
    localStorage.setItem('copyData', JSON.stringify(copyText));
  }

  back() {
    let obj: any;
    if (localStorage.getItem('loginData')) {
      obj = JSON.parse(localStorage.getItem('loginData'))
    }
    const areEqual = this.compareURLs(this.perviousRouteService.getCurrentUrl, this.perviousRouteService.getPreviousUrl);
    if (areEqual) {
      if(obj?.roleName=='employee'){
        this.router.navigate(['auth/employee/home']);
      }else if(obj?.roleName=="employer_systemadmin"){
        this.router.navigate(['auth/employer/home']);
      }
    } else {
      this.router.navigateByUrl(this.perviousRouteService.getPreviousUrl);
    }
  }

  compareURLs(url1: string, url2: string): boolean {
    const index1 = url1.indexOf('?');
    const index2 = url2.indexOf('?');

    const baseURL1 = index1 !== -1 ? url1.substring(0, index1) : url1;
    const baseURL2 = index2 !== -1 ? url2.substring(0, index2) : url2;

    return baseURL1 === baseURL2;
  }

  scrollTo(key) {
    setTimeout(() => {
      let el = document.getElementById(key);
      if (el) {
        el.scrollIntoView();
      }
    }, 0);
  }

}
