import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpMethod } from '../../../../core/enums/http-handlers';
import { AppService } from '../../../../core/services/app.service';
import { CommonService } from '../../../../core/services/common.service';
import { SearchJobsService } from '../../../../core/services/search-jobs.service';
import { SpinnerService } from '../../../../core/services/spinner.service';

@Component({
  selector: 'app-search-jobs-results',
  templateUrl: './search-jobs-results.component.html',
  styleUrls: ['./search-jobs-results.component.scss']
})
export class SearchJobsResultsComponent implements OnInit, OnDestroy {

  // form models
  searchJob: FormGroup;

  searchJobsData = {
    getAllGobs: [],
    filtercount: null
  }
  searchJobsFilterData: any;

  defaultFreshLength = 5;
  defaultEmpLength = 5;
  defaultLocLength = 5;
  defaultEduLength = 5;
  defaultRemoteLength = 5;
  //  defaultSalaryLength = 5;

  defaultCount = true;

  maxiumExperience = 0
  minimumExperience = 0

  noOfPages: any;

  selectedPage = '1';

  loginData: any;

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    public appService: AppService,
    private spinnerService: SpinnerService,
    public searchJobsService: SearchJobsService,
    private router: ActivatedRoute,
    private route: Router
  ) {
    this.loginData = JSON.parse(localStorage.getItem('loginData'));
    // this.defaultCount = true;

    // this.router.queryParams['_value']
    this.router.queryParams.subscribe(res => {
      if(res.searchType == 'BASIC' || res.searchType == 'ADVANCED') {
        this.maxiumExperience = 0;
        this.minimumExperience = 0;
      }
      this.selectedPage = res.pageNumber || '1';
      this.searchJobModel()
      this.searchJob.patchValue(res);
      if(this.searchJob.value.noOfPages) {
        this.noOfPages = this.searchJob.value.noOfPages
      }
      const jobFilterData = sessionStorage.getItem('jobFilterData');
      this.searchJob.patchValue({
        jobFilterData: jobFilterData ? jobFilterData : ''
      });
      this.searchJobsData = {
        getAllGobs: [],
        filtercount: null
      }
      this.searchJobs();
    })

  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    // sessionStorage.removeItem('jobFilterData')
  }


  searchJobModel() {
    this.searchJob = this.fb.group({
      jobTitle: ['', Validators.required],
      keySkills: [''],
      location: [''],
      employementType: [''],
      freshness: [''],
      education: [''],
      maxiumExperience: [''],
      minimumExperience: [''],
      remoteOptions: [''],
      salary: [''],
      employeeId: [this.loginData ? this.loginData.id.toString() : '0'],
      searchType: [''],
      jobFilterData: [],
      pageNumber: ['1'],
      resultsPerPage: ['10'],
      isVeterans: [false],
      isDifferentlyAbled: [false],
      isFresher: [false],
      isSeniorCitizen: [false],
      noOfPages: [''],
      totalNoOfjobs: [''],
      isBrowseJobs: [false]
    });

  }

  searchJobs() {
    if(this.searchJob.value.pageNumber == 1 && (this.searchJob.value.searchType == 'BASIC' || this.searchJob.value.searchType == 'ADVANCED')) {
      this.searchJob.patchValue({
        jobFilterData: ''
      })
      sessionStorage.setItem('jobFilterData', '')
    }
    const url = `${this.appService.searchJobs}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GETWITHOUTHEADERS,
      requestObj: this.searchJob.value
    }
    this.commonService.commonApiCall(apiObj, (resp, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && resp.data) {
        // if(this.searchJob.value.pageNumber == 1 && (this.searchJob.value.searchType == 'BASIC' || this.searchJob.value.searchType == 'ADVANCED')) {
        sessionStorage.setItem('jobFilterData', JSON.stringify(resp.data.filtercount))
        this.searchJob.patchValue({
          jobFilterData: JSON.stringify(resp.data.filtercount)
        })
        // if (this.defaultCount) {
          this.searchJobsData = { ...resp.data };
          this.commonService.scrollTo('search_basic_top');
          // resp.data.filtercount.locationData[0].locationName = "USA"
          this.searchJobsFilterData = resp.data.filtercount;
          
        // } 
        // else {
        //   this.searchJobsData = resp.data;
        // }
        if (resp.data.hasOwnProperty('noOfPages') && resp.data.noOfPages) {
          this.noOfPages = resp.data.noOfPages * (+this.searchJob.value.resultsPerPage)
          this.searchJob.patchValue({
            noOfPages: this.noOfPages
          })
        }
        if (resp.data.hasOwnProperty('totalNoOfjobs') && resp.data.totalNoOfjobs) {
          this.searchJob.patchValue({
            totalNoOfjobs: resp.data.totalNoOfjobs
          })
        }
        Object.keys(this.searchJobsFilterData).forEach(key => {
          this.searchJobsFilterData[key] && this.searchJobsFilterData[key].length && this.searchJobsFilterData[key].map(el => {
            el.checked = false
            if (key === 'employeementTypeData' && this.searchJob.value.employementType) {
              el.checked = this.searchJob.value.employementType.split(',').some(res => res == el.id) ? true : false
            } else if (key === 'remoteOptionData' && this.searchJob.value.remoteOptions) {
              el.checked = this.searchJob.value.remoteOptions.split(',').some(res => res == el.remoteOptionId) ? true : false
              // } else if(key === 'salaryRanges' && this.searchJob.value.salary) {
              //   el.checked = this.searchJob.value.salary.split(',').some(res => res == el.salaryId) ? true : false
            } else if (key === 'freshness' && this.searchJob.value.freshness) {
              el.checked = this.searchJob.value.freshness.split(',').some(res => res == el.id) ? true : false
            } else if (key === 'education' && this.searchJob.value.education) {
              el.checked = this.searchJob.value.education.split(',').some(res => res == el.qualificationId) ? true : false
            } else if (key === 'locationData' && this.searchJob.value.location) {
              el.checked = this.searchJob.value.location.split('|').some(res => res == el.locationName) ? true : false
            }
          });
        })
        if (this.searchJob.value.maxiumExperience) {
          this.maxiumExperience = this.searchJob.value.maxiumExperience;
        }
        if (this.searchJob.value.minimumExperience) {
          this.minimumExperience = this.searchJob.value.minimumExperience
        }
        // this.showPagePerIndex = [];
        // for(let i = 0; i < this.searchJobsData?.getAllGobs.length; i+20 ) {
        //  this.showPagePerIndex.push(i);
        // }
      }
    });
  }

  getCount(key, id, val) {
    const array = this.searchJobsData && this.searchJobsData.filtercount && this.searchJobsData.filtercount[key] ? this.searchJobsData.filtercount[key] : [];
    if (!array.length) {
      return 0
    }
    const obj = array && array.length && array.find(res => res[id] == val)
    return obj ? obj.count : 0
  }

  filterValue(event, value, key, type, i) {
    var val = this.searchJob.get(key).value;
    if (key == 'minimumExperience' || key == 'maxiumExperience') {
      val = key == 'minimumExperience' ? this.minimumExperience : this.maxiumExperience;
      val = val.toString()
      this.searchJob.patchValue({
        minimumExperience: this.minimumExperience,
        maxiumExperience: this.maxiumExperience
      })
      // val = event.target.value;
    } else {
      if (event.target.checked) {
        val = (key == 'location') ? (val ? (val + '|' + value) : value.toString()) : (val ? (val + ',' + value) : value.toString());
      } else {
        val = (key == 'location') ? (val.split('|').filter(res => res != value).join('|')) : (val.split(',').filter(res => res != value).join(','));
      }
    }
    if (!(type == 'minimumExperience' || type == 'maxiumExperience')) {
      this.searchJobsFilterData[type][i].checked = event.target.checked
    }
    let searchTypes = this.updateSearchType();
    if(this.searchJob.value.minimumExperience || this.searchJob.value.maxiumExperience) {
      searchTypes = searchTypes ? searchTypes + ',' + 'experience' : 'experience';
    }
    this.searchJob.patchValue({
      [key]: val,
      searchType: searchTypes ? searchTypes : 'BASIC',
      pageNumber: '1',
      jobFilterData: ''
    })
    // this.defaultCount = false;
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData && loginData.id) {
      this.route.navigate(['auth/employee/search-jobs/job-results'], { queryParams: this.searchJob.value })
    } else {
      this.route.navigate(['job-search'], { queryParams: this.searchJob.value })
    }
  }

  updateSearchType() {
    let str = '';
    for (const key in this.searchJobsFilterData) {
      if (this.searchJobsFilterData[key] && this.searchJobsFilterData[key].length) {
        if (this.searchJobsFilterData[key].some(res => res.checked)) {
          str = str ? str + ',' + key : key;
        }
      }
    }
    return str
  }

  resultsPerPage(event) {
    // this.defaultCount = false;
    this.searchJob.patchValue({
      resultsPerPage: event.resultsPerPage,
      pageNumber: event.pageNumber,
      jobFilterData: ''
    })
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData && loginData.id) {
      this.route.navigate(['auth/employee/search-jobs/job-results'], { queryParams: this.searchJob.value })
    } else {
      this.route.navigate(['job-search'], { queryParams: this.searchJob.value })
    }

  }

  shareEmit(event) {
    this.commonService.shareEmit(event);
  }

}
