<div class="containerfluid px-0" *ngIf="getJobDetailsbyJobIdData">
  <div class="row">
    <div class="col-lg-8">
      <div class="card search-job-details mb-3">
        <div class="row">
          <div class="col-auto">
            <div class="company-details-logo">
              <img
                src="{{
                  getJobDetailsbyJobIdData?.companyLogoPath
                    ? appService.domain +
                      getJobDetailsbyJobIdData?.companyLogoPath
                    : 'assets/images/Employee_1.png'
                }}"
                class="profile-one w-100"
                alt="..."
              />
            </div>
          </div>
          <div class="col">
            <div class="search-company-details">
              <div class="job-title mb-2">
                {{
                  getJobDetailsbyJobIdData?.jobs[0]?.jobTitle
                    ? getJobDetailsbyJobIdData?.jobs[0]?.jobTitle
                    : "N/A"
                }}
                <span class="job-id"
                  >Job Code:
                  {{
                    getJobDetailsbyJobIdData?.jobs[0]?.jobCode
                      ? getJobDetailsbyJobIdData?.jobs[0]?.jobCode
                      : "N/A"
                  }}</span
                >
              </div>
              <div class="company-name mb-2">
                {{
                  getJobDetailsbyJobIdData.companyName
                    ? getJobDetailsbyJobIdData.companyName
                    : "N/A"
                }}
              </div>
              <div class="company-name mb-2">
                {{
                  getJobDetailsbyJobIdData?.jobs[0]?.clientName
                    ? getJobDetailsbyJobIdData?.jobs[0]?.hideClient
                      ? "Not Disclosed"
                      : getJobDetailsbyJobIdData?.jobs[0]?.clientName
                    : "N/A"
                }}
              </div>
              <div class="row d-flex mb-2">
                <div class="job-details col-lg-4">
                  <img
                    src="assets/images/employment_type.svg"
                    class="location-field"
                    alt="location"
                    matTooltip="Employment-Type"
                  />
                  <span class="text ml-2">
                    {{
                      getJobDetailsbyJobIdData?.jobs[0]?.employementType
                        ? getJobDetailsbyJobIdData?.jobs[0]?.employementType
                        : "N/A"
                    }}
                  </span>
                </div>
                <div class="col job-details">
                  <img
                    src="assets/images/salary.svg"
                    class="location-field"
                    alt="location"
                    matTooltip="Salary"
                  />
                  <span class="text ml-2">
                    {{
                      getJobDetailsbyJobIdData?.jobs[0]?.salary
                        ? getJobDetailsbyJobIdData?.jobs[0]?.hideSalary
                          ? "Not Disclosed"
                          : getJobDetailsbyJobIdData?.jobs[0]?.salary
                        : "N/A"
                    }}
                  </span>
                </div>
              </div>
              <div class="row d-flex mb-2">
                <div class="col-lg-4 job-details">
                  <img
                    src="assets/images/location.svg"
                    class="location-field"
                    alt="location"
                    matTooltip="Location"
                  />
                  <span class="text ml-2">{{
                    getJobDetailsbyJobIdData?.jobs[0]?.location
                      ? getJobDetailsbyJobIdData?.jobs[0]?.location
                      : "N/A"
                  }}</span>
                </div>
                <div class="job-details col">
                  <img
                    src="assets/images/experience_icon.svg"
                    width="13"
                    class="location-field"
                    alt="location"
                    matTooltip="Experience"
                  />
                  <span
                    class="text ml-2"
                    *ngIf="
                      getJobDetailsbyJobIdData?.jobs[0]?.minExperience !=
                        undefined &&
                        getJobDetailsbyJobIdData?.jobs[0]?.maxExperience !=
                          undefined;
                      else experienceTemp
                    "
                    >{{
                      getJobDetailsbyJobIdData?.jobs[0]?.minExperience !=
                      undefined
                        ? getJobDetailsbyJobIdData?.jobs[0]?.minExperience
                        : "N/A"
                    }}
                    -
                    {{
                      getJobDetailsbyJobIdData?.jobs[0]?.maxExperience !=
                      undefined
                        ? getJobDetailsbyJobIdData?.jobs[0]?.maxExperience
                        : "N/A"
                    }}
                    Years</span
                  >
                  <ng-template #experienceTemp>
                    <span class="text ml-2">
                      {{
                        getJobDetailsbyJobIdData?.jobs[0]?.lookingFor
                          ? getJobDetailsbyJobIdData?.jobs[0]?.lookingFor
                          : "N/A"
                      }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="row d-flex mb-2">
                <div class="col-lg-4 job-details">
                  <img
                    src="assets/images/graduation-cap.svg"
                    class="location-field"
                    alt="location"
                    matTooltip="Education"
                  />
                  <span class="text ml-2">{{
                    getJobDetailsbyJobIdData?.jobs[0]?.education
                      ? getJobDetailsbyJobIdData?.jobs[0]?.education
                      : "N/A"
                  }}</span>
                </div>
                <div class="job-details col">
                  <img
                    src="assets/images/remote_option.svg"
                    class="location-field"
                    alt="location"
                    matTooltip="Remote Option"
                  />
                  <span class="text ml-2">{{
                    getJobDetailsbyJobIdData?.jobs[0]?.remoteOption
                      ? getJobDetailsbyJobIdData?.jobs[0]?.remoteOption
                      : "N/A"
                  }}</span>
                </div>
              </div>
              <div class="skills mb-3">
                <img
                  src="assets/images/skills.svg"
                  class="mr-2"
                  alt="location"
                  matTooltip="Skills"
                />
                <span>
                  {{
                    getJobDetailsbyJobIdData?.jobs[0]?.keySkills
                      ? getJobDetailsbyJobIdData?.jobs[0]?.keySkills
                      : "N/A"
                  }}
                </span>
              </div>
              <div
                class="footer-buttons xs-footer-buttons mb-2 d-flex align-i-center justify-content-between"
              >
                <div class="posted-date xs-posted-date">
                  <span class="green-dot"
                    ><span class="dot"></span
                    ><span class="label">Posted Date:</span>
                    {{
                      getJobDetailsbyJobIdData?.jobs[0]?.createdOn
                        ? (getJobDetailsbyJobIdData?.jobs[0]?.createdOn
                          | date : "MM/dd/yyyy")
                        : "N/A"
                    }}</span
                  >
                  <span class="blue-dot"
                    ><span class="dot"></span
                    ><span class="label">Job Applicants:</span>
                   <span>
                    {{
                      getJobDetailsbyJobIdData?.jobs[0]?.jobApplicationsCount >=
                      0
                        ? getJobDetailsbyJobIdData?.jobs[0]
                            ?.jobApplicationsCount
                        : "N/A"
                    }}
                   </span> </span
                  >
                </div>
                <div class="d-flex p-relative xs-share-opt">
                  <div
                    class="vacancies mr-3 pr-2 align-self-mbl-start align-self-center"
                    *ngIf="getJobDetailsbyJobIdData?.jobs[0]?.jobVacancies"
                  >
                    <img
                      src="assets/images/Vacancies.svg"
                      class="mr-2"
                      alt="location"
                      matTooltip="Vacancies"
                    />
                    {{
                      getJobDetailsbyJobIdData?.jobs[0]?.jobVacancies
                        ? getJobDetailsbyJobIdData?.jobs[0]?.jobVacancies
                        : "N/A"
                    }}
                    Vacancies
                  </div>

                  <ng-container *ngIf="loginData">
                    <div
                      class="share-icons"
                      *ngIf="
                        getJobDetailsbyJobIdData?.jobs[0]?.jobId == isShown
                      "
                    >
                      <span
                        ><img
                          src="assets/images/home-page-images/email.png"
                          matTooltip="Email"
                          alt="Email"
                          (click)="
                            selectShare('email', getJobDetailsbyJobIdData);clearToggle()
                          "
                      /></span>
                      <span
                        ><img
                          src="assets/images/home-page-images/whatsapp.png"
                          matTooltip="Whatsapp"
                          alt="Whatsapp"
                          (click)="
                            selectShare('whatsApp', getJobDetailsbyJobIdData);clearToggle()
                          "
                      /></span>
                      <span
                        ><img
                          src="assets/images/home-page-images/copy.png"
                          matTooltip="Copy"
                          alt="Copy"
                          (click)="
                            selectShare('copy', getJobDetailsbyJobIdData);clearToggle()
                          "
                      /></span>
                      <span
                        ><img
                          src="assets/images/home-page-images/link.png"
                          matTooltip="Copy Link"
                          alt="Copy Link"
                          (click)="
                            selectShare('copyLink', getJobDetailsbyJobIdData);clearToggle()
                          "
                      /></span>
                      <span
                        ><img
                          src="assets/images/home-page-images/linkedin.png"
                          matTooltip="Linkedin"
                          alt="Linkedin"
                          (click)="
                            selectShare('linkedin', getJobDetailsbyJobIdData);clearToggle()
                          "
                      /></span>
                    </div>
                    <div class="d-flex flex-wrap flex-mbl-column">
                      <button
                        class="jc-button share-btn mx-3"
                        *ngIf="loginData"
                        (click)="
                          toggleShow(getJobDetailsbyJobIdData?.jobs[0]?.jobId)
                        "
                      >
                        <img
                          src="assets/images/share_post.svg"
                          alt="location"
                        />
                        Share
                      </button>
                      <!-- <select  (change)="selectShare($event, getJobDetailsbyJobIdData?.jobs[0])">
                                            <option selected disabled> Share </option>
                                            <option value="email"> Email </option>
                                            <option value="copy"> Copy </option>
                                            <option value="whatsApp"> WhatsApp </option>
                                            <option value="linkedin"> LinkedIn </option>
                                        </select> -->

                      <button
                        class="jc-button apply-btn mr-3"
                        [ngClass]="{
                          'apply-btn-success':
                            getJobDetailsbyJobIdData?.jobs[0]?.jobApplyed
                        }"
                        (click)="
                          applyJobsinEmployee(getJobDetailsbyJobIdData?.jobs[0])
                        "
                      >
                        {{
                          getJobDetailsbyJobIdData?.jobs[0]?.jobApplyed
                            ? "Applied"
                            : "Apply"
                        }}
                      </button>

                      <button
                        class="jc-button apply-btn"
                        [ngClass]="{
                          'apply-btn-success':
                            getJobDetailsbyJobIdData?.jobs[0]?.jobSaved
                        }"
                        (click)="
                          saveJobsinEmployee(getJobDetailsbyJobIdData?.jobs[0])
                        "
                      >
                        {{
                          getJobDetailsbyJobIdData?.jobs[0]?.jobSaved
                            ? "Saved"
                            : "Save"
                        }}
                      </button>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="!loginData">
                    <!-- <button class="jc-button share-btn pr-2 mr-3" (click)="register()"> Register to apply </button> -->
                    <button class="jc-button apply-btn" (click)="login()">
                      Login to Apply
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-0 Job-details-info mb-3">
        <div class="card-title">Job Information</div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-lg-12 job-info">
              <p>
                <span
                  [innerHtml]="getJobDetailsbyJobIdData?.jobs[0]?.description"
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card p-0 Job-details-info mb-3">
        <div class="card-title">Roles and Responsibilities</div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-lg-12 job-info">
              <span
                [innerHtml]="
                  getJobDetailsbyJobIdData?.jobs[0]?.rolesAndResponsibilities
                "
              ></span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="card p-0 Job-details-info mb-3">
                <div class="card-title">Contact Person Details</div>
                <div class="card-body p-3">
                    <div class="row">
                        <div class="col-lg-12 job-info">
                            <div class="d-block mb-2"> <label class="mr-2">Contact Person Name: </label>{{
                                getJobDetailsbyJobIdData?.contactPerson[0].contactPersonName ?
                                getJobDetailsbyJobIdData?.contactPerson[0].contactPersonName : 'N/A' }}</div>
                            <div class="d-block mb-2"><label class="mr-2">Job Title (Designation): </label> {{
                                getJobDetailsbyJobIdData?.contactPerson[0].jobTitle ?
                                getJobDetailsbyJobIdData?.contactPerson[0].jobTitle : 'N/A' }} </div>
                            <div class="d-block mb-2"><label class="mr-2">Email ID: </label>{{
                                getJobDetailsbyJobIdData?.contactPerson[0].email ?
                                getJobDetailsbyJobIdData?.contactPerson[0].email : 'N/A' }}</div>
                            <div class="d-block mb-2"><label class="mr-2">Contact Person: </label>{{
                                getJobDetailsbyJobIdData?.contactPerson[0].mobileNo ?
                                getJobDetailsbyJobIdData?.contactPerson[0].mobileNo : 'N/A' }}</div>
                        </div>
                    </div>
                </div>
            </div> -->
      <div class="card p-0 Job-details-info mb-3">
        <div class="card-title">Company Details</div>
        <div class="card-body p-3">
          <div class="row">
            <div class="col-lg-12 job-info">
              <div class="d-block mb-2">
                <label class="mr-2">Company Type: </label
                >{{
                  getJobDetailsbyJobIdData?.companyType
                    ? getJobDetailsbyJobIdData?.companyType
                    : "N/A"
                }}
              </div>
              <div class="d-block mb-2">
                <label class="mr-2">Company Name: </label
                >{{
                  getJobDetailsbyJobIdData?.companyName
                    ? getJobDetailsbyJobIdData?.companyName
                    : "N/A"
                }}
              </div>
              <p>
                <span class="d-block mb-2 abou-company">About Company:</span>
                <span
                  [innerHtml]="getJobDetailsbyJobIdData?.description"
                ></span>
              </p>
            </div>
          </div>
          <div class="row job-info-icons">
            <div class="col-lg-5 d-flex mb-2">
              <img
                src="assets/images/website.svg"
                class="mr-2"
                alt="location"
              />
              <a
                href="{{
                  getJobDetailsbyJobIdData?.companyWebSite
                    ? getJobDetailsbyJobIdData?.companyWebSite
                    : '#'
                }}"
                target="_blank"
                >{{
                  getJobDetailsbyJobIdData?.companyWebSite
                    ? getJobDetailsbyJobIdData?.companyWebSite
                    : "N/A"
                }}</a
              >
            </div>

            <div class="col-lg-5 d-flex mb-2">
              <img
                src="assets/images/facebook-1.svg"
                class="mr-2"
                alt="location"
              />
              <span>{{
                getJobDetailsbyJobIdData?.facebookLink
                  ? getJobDetailsbyJobIdData?.facebookLink
                  : "N/A"
              }}</span>
            </div>

            <div class="col-lg-5 d-flex mb-2">
              <img
                src="assets/images/contact_number.svg"
                class="mr-2"
                alt="location"
              />
              <a
                href="tel:{{
                  getJobDetailsbyJobIdData?.mobileNo
                    ? getJobDetailsbyJobIdData?.mobileNo
                    : 'N/A'
                }}"
                >{{
                  getJobDetailsbyJobIdData?.mobileNo
                    ? getJobDetailsbyJobIdData?.mobileNo
                    : "N/A"
                }}</a
              >
            </div>

            <div class="col-lg-5 d-flex mb-2">
              <img
                src="assets/images/twitter-1.svg"
                class="mr-2"
                alt="location"
              />
              <span>{{
                getJobDetailsbyJobIdData?.twitterLink
                  ? getJobDetailsbyJobIdData?.twitterLink
                  : "N/A"
              }}</span>
            </div>

            <div class="col-lg-5 d-flex mb-2">
              <img
                src="assets/images/email.png"
                class="mr-2 email"
                alt="location"
              />
              <a
                href="mailto:{{
                  getJobDetailsbyJobIdData?.email
                    ? getJobDetailsbyJobIdData?.email
                    : 'N/A'
                }}"
                >{{
                  getJobDetailsbyJobIdData?.email
                    ? getJobDetailsbyJobIdData?.email
                    : "N/A"
                }}</a
              >
            </div>

            <div class="col-lg-5 d-flex mb-2">
              <img
                src="assets/images/linkedin.svg"
                class="mr-2"
                alt="location"
              />
              <span>{{
                getJobDetailsbyJobIdData?.linkedInLink
                  ? getJobDetailsbyJobIdData?.linkedInLink
                  : "N/A"
              }}</span>
            </div>

            <div class="col-lg-5 d-flex mb-2">
              <img
                src="assets/images/location.svg"
                class="mr-2 mt-1 align-self-start"
                alt="location"
              />
              <span
                class="text"
                *ngIf="
                  getJobDetailsbyJobIdData?.address1 ||
                    getJobDetailsbyJobIdData?.address2;
                  else na_content
                "
              >
                {{ getJobDetailsbyJobIdData?.address1 }},
                {{ getJobDetailsbyJobIdData?.address2 }}
              </span>
              <ng-template #na_content
                ><span class="text ml-2">N/A</span></ng-template
              >
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 job-info">
              <span class="d-block mb-1 abou-company">Map Location:</span>
              <div
                class="company-location"
                *ngIf="getJobDetailsbyJobIdData?.mapLocationLink"
              >
                <div class="mapouter">
                  <div class="gmap_canvas">
                    <iframe
                      id="gmap_canvas"
                      [src]="
                        sanitizer.bypassSecurityTrustResourceUrl(
                          getJobDetailsbyJobIdData?.mapLocationLink
                        )
                      "
                      width="600"
                      height="450"
                      frameborder="0"
                      style="border: 0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      allowfullscreen
                    >
                    </iframe>
                    <a href="https://soap2day-to.com">soap2day</a><br />
                    <style>
                      .mapouter {
                        position: relative;
                        text-align: right;
                        height: 160px;
                        width: 550px;
                      }</style
                    ><a href="https://www.embedgooglemap.net"
                      >adding google map to wordpress</a
                    >
                    <style>
                      .gmap_canvas {
                        overflow: hidden;
                        background: none !important;
                        height: 160px;
                        width: 550px;
                      }
                    </style>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <!-- <div class="find-job">
                <form class="py-3" [formGroup]="searchJob">
                    <div class="search-job-filter-panel">
                        <div class="row">
                            <div class="col-12 mb-3">
                                <app-autocomplete [invalid]="submitted && searchJob.controls.jobTitle.invalid"
                                    [id]="'name'" [text]="'name'" [customInput]="true"
                                    [placeholder]="'Search by Skills or Job Title'" [value]="searchJob.value.jobTitle"
                                    [options]="searchJobsService.getJobSearchDropdownDataList.jobtitlesandskills"
                                    (valueEmit)="clearValue('jobTitle')"
                                    (valueSelectedEmit)="setValues($event,'jobTitle', 'name')">
                                </app-autocomplete>
                                <div class="error_text mt-0" *ngIf="submitted && searchJob.controls.jobTitle.invalid">
                                    <div class="error_text mt-0" *ngIf="searchJob.controls.jobTitle.errors.required">
                                        Please enter your skills, job title
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mb-3 p-relative">
                                <app-autocomplete [id]="'name'" [text]="'name'" [placeholder]="'Location'"
                                    [value]="searchJob.value.location"
                                    [options]="searchJobsService.getJobSearchDropdownDataList.location"
                                    (valueEmit)="clearValue('location')"
                                    (valueSelectedEmit)="setValues($event,'location', 'name')">
                                </app-autocomplete>
                                <img src="assets/images/location_field.svg" class="location-field-input" alt="location">
                            </div>
                            <div class="w-100">
                                <button class="jc-button" (click)="basicSearchJob()">Find Jobs</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div> -->

      <app-recommended-jobs></app-recommended-jobs>
    </div>
  </div>
</div>
