<div
  class="card d-flex justify-content-center align-items-center flex-column mb-3"
  *ngIf="!list?.length"
>
  <img class="text-bottom" width="300" src="assets/images/nodatafound.png" />
  <h5 class="no_data_found">No results on your search</h5>
</div>

<div *ngIf="list?.length">
  <div class="row" *ngIf="list.length && totalNoOfjobs">
    <div class="col header-filters d-flex justify-content-between mb-2">
      <span class="result-count">
        {{ pageHeader() }} of {{ totalNoOfjobs }} {{ jobTitle }}
      </span>
    </div>
  </div>

  <div
    class="card search-job-details mb-3"
    *ngFor="
      let job of list
        | paginate
          : { itemsPerPage: pageSize, currentPage: p, totalItems: noOfPages }
    "
  >
    <div class="row">
      <div class="col-auto col-xs-12 xs-d-flex">
        <div class="user-photo xs-mb-10">
          <img
            src="{{
              job?.companyLogoPath
                ? appService.domain + job?.companyLogoPath
                : 'assets/images/default_comapny_icon.svg'
            }}"
            class="location-field"
            alt="location"
          />
        </div>
        <div class="search-company-details xs-d-block">
          <div class="job-title mb-2 cursor-p" (click)="showDetails(job)">
            <div class="xs-px-12 xs-mb-5">
              {{ job.jobTitle }}
              <span class="job-id xs-d-block xs-ml-0"
                >Job Code: {{ job.jobCode }}
              </span>
            </div>
            <div class="posted-date" *ngIf="showApplied">
              <span class="green-dot mr-1"></span>
              <span class="p-label jc-text-light ml-1 mr-1">Posted Date:</span>
              {{
                job?.createdOn ? (job?.createdOn | date : "MM/dd/YYYY") : "N/A"
              }}
            </div>
            <div class="company-name mb-2 xs-px-12 xs-d-block">
              {{ job.companyName ? job.companyName : "N/A" }}
            </div>
          </div>
        </div>
      </div>
      <div class="col pl-0 xs-px-12">
        <div class="search-company-details">
          <div
            class="job-title mb-2 cursor-p d-flex justify-content-between xs-d-none"
            (click)="showDetails(job)"
          >
            <div class="">
              {{ job.jobTitle }}
              <span class="job-id">Job Code: {{ job.jobCode }} </span>
            </div>
            <div class="posted-date" *ngIf="showApplied">
              <span class="green-dot mr-1"></span>
              <span class="p-label jc-text-light ml-1 mr-1">Posted Date:</span>
              {{
                job?.createdOn ? (job?.createdOn | date : "MM/dd/YYYY") : "N/A"
              }}
            </div>
          </div>
          <div class="company-name mb-2 xs-d-none">
            {{ job.companyName ? job.companyName : "N/A" }}
          </div>
          <div class="row d-flex mb-2 xs-mb-0 justify-content-between">
            <span class="job-details d-flex col-xl-auto col-lg-6 col-xs-6"
              ><img
                src="assets/images/experience_icon.svg"
                width="13"
                class="location-field"
                alt="location"
              />
              <span
                class="text ml-2"
                *ngIf="
                  job?.minExperience != undefined &&
                    job?.maxExperience != undefined;
                  else experienceTemp
                "
              >
                {{ job.minExperience != undefined ? job.minExperience : "N/A" }}
                -
                {{ job.maxExperience != undefined ? job.maxExperience : "N/A" }}
                Years</span
              >
              <ng-template #experienceTemp>
                <span class="text ml-2">
                  {{ job?.lookingFor ? job?.lookingFor : "N/A" }}
                </span>
              </ng-template>
            </span>
            <span class="job-details d-flex col-xl-3 col-lg-6 col-xs-6"
              ><img
                src="assets/images/employment_type.svg"
                class="location-field"
                alt="location"
                matTooltip="employment-type"
              />
              <span class="text ml-2">
                {{
                  job.employeementTypeName ? job.employeementTypeName : "N/A"
                }}
              </span></span
            >
            <span class="job-details d-flex col-xl-5 col-lg-12 xs-my-10"
              ><img
                src="assets/images/location.svg"
                class="location-field"
                alt="location"
              />
              <span class="text ml-2">{{
                job.location ? job.location : "N/A"
              }}</span></span
            >
          </div>
          <div class="job-description d-flex mb-2">
            <img
              src="assets/images/job_description.svg"
              class="mr-2"
              alt="location"
              matTooltip="job-description"
            />
            <span class="roles">
              {{
                commonService.convertHtmlToString(job.rolesAndResponsibilities)
              }}
              <span (click)="showDetails(job)" class="show-more">
                Show More..
              </span>
            </span>
          </div>
          <div class="skills d-flex">
            <img
              src="assets/images/skills.svg"
              class="mr-2"
              alt="location"
              matTooltip="skills"
            />
            <span> {{ job.keySkills }} </span>
          </div>
          <div
            class="footer-buttons mt-2 mb-2 d-flex align-i-center justify-content-between xs-row"
            *ngIf="showApply"
          >
            <div class="col-xs-12 xs-justify-between xs-mb-10">
              <button class="last-update-btn">
                <img
                  src="assets/images/postedicon.svg"
                  class="mr-2"
                  alt="location"
                />
                {{ commonService.getTimeDifference(job.createdOn) }} ago
              </button>
            </div>

            <div class="ml-3 col-xs-12 xs-justify-between p-relative xs-ml-0">
              <div class="share-icons" *ngIf="job.jobId == isShown">
                <span
                  ><img
                    src="assets/images/home-page-images/email.png"
                    matTooltip="Email"
                    alt="Email"
                    (click)="selectShare('email', job);clearToggle()"
                /></span>
                <span
                  ><img
                    src="assets/images/home-page-images/whatsapp.png"
                    matTooltip="Whatsapp"
                    alt="Whatsapp"
                    (click)="selectShare('whatsApp', job);clearToggle()"
                /></span>
                <span
                  ><img
                    src="assets/images/home-page-images/copy.png"
                    matTooltip="Copy"
                    alt="Copy"
                    (click)="selectShare('copy', job);clearToggle()"
                /></span>
                <span
                  ><img
                    src="assets/images/home-page-images/link.png"
                    matTooltip="Copy Link"
                    alt="Copy Link"
                    (click)="selectShare('copyLink', job);clearToggle()"
                /></span>
                <span
                  ><img
                    src="assets/images/home-page-images/linkedin.png"
                    matTooltip="Linkedin"
                    alt="Linkedin"
                    (click)="selectShare('linkedin', job);clearToggle()"
                /></span>
              </div>

              <button
                class="jc-button share-btn me-3"
                *ngIf="loginData"
                (click)="toggleShow(job.jobId)"
              >
                <img src="assets/images/share_post.svg" alt="location" /> Share
              </button>

              <button
                class="jc-button apply-btn"
                [ngClass]="{ 'apply-btn-success': job.jobApplyed }"
                (click)="showAppliedDetails(job)"
              >
                {{ job.jobApplyed ? "Applied" : "Apply" }}
              </button>

              <button
                class="jc-button apply-btn ml-3"
                *ngIf="!job.jobApplyed && loginData"
                [ngClass]="{ 'apply-btn-success': job.jobSaved }"
                (click)="showSaveDetails(job)"
              >
                {{ job.jobSaved ? "Saved" : "Save" }}
              </button>
            </div>
          </div>
          <div
            class="application-process mt-3 d-flex justify-content-between"
            *ngIf="showApplied"
          >
            <div class="applied-section d-flex">
              <div
                class="applied"
                *ngFor="let status of job.employeeJobStatusdata; let i = index"
                [ngClass]="{
                  success: status.status == 2,
                  rejected:
                    status.status == 2 && status.jobStatusName == 'Rejected',
                  selected:
                    status.status == 2 && status.jobStatusName == 'Selected'
                }"
              >
                <div class="d-flex align-items-center status-bar">
                  <span
                    [ngClass]="status.status == 3 ? 'circle-white' : 'circle'"
                  ></span>
                  <span
                    [ngClass]="{
                      'border-plain': status.status == 1,
                      'border-dashed': status.status == 2
                    }"
                  ></span>
                </div>
                <div
                  class="text-section text-center"
                  [ngClass]="{ 'jc-text-success': status.status == 2 }"
                >
                  <span class="d-block">{{ status.jobStatusName }}</span>
                  <!-- <span class="d-block">{{ status.createdOn | date: 'dd MMMM' }}</span> -->
                </div>
              </div>
            </div>
            <!-- <div class="text-section">
                                
                            </div> -->
            <div class="d-flex">
              <div class="applied-date">
                {{ job.jobApplyedDate ? job.jobApplyedDate : job.jobSavedDate }}
              </div>
              <div *ngIf="job?.jobSaved && showDelete">
                <button
                  class="jc-button apply-btn ml-3"
                  (click)="deleteJob(job)"
                >
                  Delete
                </button>
              </div>
            </div>
            <!-- <div class="applied-section d-flex">
                            <div class="applied ml-3" >
                                <span class="circle"></span>
                                <span class="border-plain" ></span>
                        </div>
                            <div class="applied">
                                <span class="circle"></span>
                                <span class="border-plain"></span>
                            </div>
                            <div class="applied success">
                                    <span class="circle"></span>
                                    <span class="border-dashed"></span>
                            </div>
                            <div class="recruiter-action">
                                    <span class="circle-white"></span>
                            </div>
                        </div>
                        <div class="text-section">
                            <div class="text-applied text-center" >
                                <span class="d-block">Applied 19 July</span>
                            </div>
                            <div class="text-application-viewed text-center">
                                <span class="d-block">Application Viewed 19 July</span>
                            </div>
                            <div class="text-recruiter-inprocess text-center jc-text-success">
                                <span class="d-block">Interview Inprocess 14 July</span>
                            </div>
                            <div class="text-recruiter-action text-center">
                                <span class="d-block">Awaiting Recruiter Action</span>
                            </div>
                        </div>  -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="pagination d-flex justify-content-between xs-row"
    *ngIf="list.length"
  >
    <span class="count-number align-self-center col-xs-12 xs-mb-10">
      <nav
        class="navbar navbar-expand-lg menubar d-flex p-0 cursor-p justify-content-mbl-center"
      >
        <span style="color: red">Items per page :</span>
        <div class="dropdown">
          <a
            class="nav-link dropdown-toggle p-0"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >{{ pageSize }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <span class="menu-active-bg"></span>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(10)"
              >
                10
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(20)"
              >
                20
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(40)"
              >
                40
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(60)"
              >
                60
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(80)"
              >
                80
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(100)"
              >
                100
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(120)"
              >
                120
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(140)"
              >
                140
              </a>
            </li>
            <li>
              <a
                class="dropdown-item cursor-p"
                (click)="changePagePerIndex(160)"
              >
                160
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </span>
    <div class="col-xs-12">
      <pagination-controls
        (pageChange)="p = $event"
        (pageChange)="handlePageChange($event)"
      ></pagination-controls>
    </div>
  </div>
</div>
