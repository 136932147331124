<form class="pb-3 mt-3" [formGroup]="mailModelForm">

    <div class="card p-0 company-profile-overview mb-3">
        <div class="card-title">Send Email</div>
        <div class="card-body p-0">
            <div class="row m-0 p-2">
                <div class="col pt-3">

                    <div class="form-group row mb-2" *ngIf="mailModelForm.value.type == 'employee'">
                        <label class="col-sm-3 col-form-label">Send to <span class="text-danger">*</span>
                        </label>
                        <div class="col-sm-9 d-flex">
                            <app-autocomplete-chip  [id]="''" [text]="''"
                            [valuesData]="mailModelForm.value.Email" [options]="[]"
                            (valueSelectedEmit)="emails($event)"></app-autocomplete-chip>
                        </div>
                    </div>

                    <div class="form-group row mb-2" *ngIf="mailModelForm.value.type == 'employer'">
                        <label class="col-sm-3 col-form-label">Send to <span class="text-danger">*</span>
                        </label>
                        <div class="col-sm-9 d-flex">
                            <div class="send-candidate" *ngFor="let data of mailModelForm.value.names">
                                <span> {{ data }} </span>
                                <span class="d-flex ml-2" (click)="removeEmp(data.id)"><img src="assets/images/close.png"
                                        class="close-icon" alt="logo"></span>
                            </div>
                        </div>
                    </div>
    
                    <div class="form-group row mb-2"  *ngIf="mailModelForm.value.type == 'employer'">
                        <label class="col-sm-3 col-form-label">
                            Job Title (Designation) <span class="text-danger">*</span></label>
                        <div class="col-md-6">
                            <app-autocomplete [invalid]="submitted && mailModelForm.controls.jobTitle.invalid"
                                [id]="'jobTitle'" [text]="'jobTitle'" [customInput]="true"
                                [value]="mailModelForm.get('jobTitle').value" [options]="getAllJobTitlesList"
                                (valueEmit)="clearValue('jobTitle')"
                                (valueSelectedEmit)="setValues($event,'jobTitle', 'jobTitle')"></app-autocomplete>
                            <div class="error_text mt-0" *ngIf="submitted && mailModelForm.controls.jobTitle.invalid">
                                <div class="error_text mt-0" *ngIf="mailModelForm.controls.jobTitle.errors.required">
                                    Please Enter Job Title(Designation)
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="form-group row mb-2">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">
                            Subject<span class="text-danger">*</span> </label>
                        <div class="col-md-7">
                            <input type="text" id="Subject" class="form-control" formControlName="Subject"
                                [ngClass]="{'is-invalid': (mailModelForm.controls.Subject.dirty && mailModelForm.controls.Subject.touched 
                            && mailModelForm.controls.Subject.errors) || (submitted && mailModelForm.controls.Subject.errors) }" />
                            <div class="error_text mt-0"
                                *ngIf="(mailModelForm.controls.Subject.dirty && mailModelForm.controls.Subject.touched 
                            && mailModelForm.controls.Subject.errors) || (submitted && mailModelForm.controls.Subject.errors)">
                                <div class="error_text mt-0" *ngIf="mailModelForm.controls.Subject.errors.required">
                                    Please Enter Subject
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-2">
                        <label for="inputEmail3" class="col-sm-3 col-form-label">
                            Message<span class="text-danger">*</span> </label>
                        <div class="col-md-9">
                            <div class="d-flex justify-content-end mb-2">
                                <button class="jc-button" (click)="paste()"> paste </button>
                            </div>
                            <quill-editor class="d-block" [modules]="editorModules" formControlName="Message"
                                [ngClass]="{'is-invalid': (mailModelForm.controls.Message.dirty && mailModelForm.controls.Message.touched 
                            && mailModelForm.controls.Message.errors) || (submitted && mailModelForm.controls.Message.errors) }"> </quill-editor>
                            <div class="error_text mt-0"
                                *ngIf="(mailModelForm.controls.Message.dirty && mailModelForm.controls.Message.touched 
                            && mailModelForm.controls.Message.errors) || (submitted && mailModelForm.controls.Message.errors)">
                                <div class="error_text mt-0" *ngIf="mailModelForm.controls.Message.errors.required">
                                    Please Enter Message
                                </div>
                            </div>
                        </div>
                    </div>
    
                </div>
            </div>
        </div>
    </div>
    <div class="card mb-3">
        <div class="row">
            <div class="col text-right">
                <!-- <button class="jc-btn-cancel" (click)="closeForm()">Close</button> -->
                <button class="jc-button ml-3" (click)="submitForm()" [disabled]="disabled">Send Email</button>
            </div>
        </div>
    </div>

</form>