<div class="container-fluid tech-news p-relative">
    <div class="row">
        <div class="col bg-image p-0">
            <img src="assets/images/admin-set-password.png"/>
        </div>
        <div class="col-md-6 set-password-content">
            <div class="register-page card">
                <form class="" [formGroup]="setPasswordForm" >
                    <div class="login-title">
                        Set Password
                    </div>
                    <div class="logo pl-2">
                        <p>Lorem Ipsum is simply dummy text of the printing.</p>
                    </div>
                    <div class="forms mt-2">
                        
                        <div class="form-group popup-controls mb-2 position-relative">
                            <label> Password<span class="text-danger">*</span><img src="assets/images/information.svg"
                                matTooltip="Password must be a minimum of 8 characters with at least 1 special character($,@,!,&,%,#), 1 number(0-9), 1 uppercase(A-Z) and 1 lowercase character(a-z)."
                                    matTooltipPosition="right" class="ml-1 password-info" alt="logo"></label>
                            <input [type]="hide ? 'password' : 'text'" placeholder="********" class="form-control bg-img-none" minlength="8" [ngClass]="{ 'is-invalid': 
                                (setPasswordForm.controls.password.dirty && setPasswordForm.controls.password.touched && setPasswordForm.controls.password.errors) ||
                                (submitted && setPasswordForm.controls.password.errors)
                            }" maxlength="16" formControlName="password">
                            <i class="eye-icon" (click)="hide = !hide" [ngClass]="(hide)?'fa fa-eye-slash':'fa fa-eye'" aria-hidden="true"></i>
                            <div class="error_text mt-0" *ngIf="(setPasswordForm.controls.password.dirty && setPasswordForm.controls.password.touched && setPasswordForm.controls.password.errors) ||
                            (submitted && setPasswordForm.controls.password.errors) 
                            ">
                                <div class="error_text mt-0" *ngIf="setPasswordForm.controls.password.errors.pattern">
                                    <small>Password must be minimum 8 characters and maximum 16 characters with at
                                        least
                                        1 special
                                        character, 1 number, 1 uppercase and 1 lowercase character.</small>
                                </div>
                                <div class="error_text mt-0" *ngIf="setPasswordForm.controls.password.errors.maxlength">
                                    Password must be max 16 characters.</div>
                                <div class="error_text mt-0" *ngIf="setPasswordForm.controls.password.errors.required">
                                    Password is required.
                                </div>
                                <div class="error_text mt-0" *ngIf="setPasswordForm.controls.password.errors.cannotContainSpace">
                                    Password cannot contain space. </div>
                            </div>
                        </div>

                        <div class="form-group popup-controls mb-2">
                            <label> Confirm Password<span class="text-danger">*</span></label>
                            <input type="password" class="form-control" placeholder="********" minlength="8" [ngClass]="{ 'is-invalid': 
                                (setPasswordForm.controls.confirmPassword.dirty && setPasswordForm.controls.confirmPassword.touched && setPasswordForm.controls.confirmPassword.errors) ||
                                (submitted && setPasswordForm.controls.confirmPassword.errors)
                            }" maxlength="16" formControlName="confirmPassword">
                            <div class="error_text mt-0" *ngIf="(setPasswordForm.controls.confirmPassword.dirty && setPasswordForm.controls.confirmPassword.touched && setPasswordForm.controls.confirmPassword.errors) ||
                                (submitted && setPasswordForm.controls.confirmPassword.errors)">
                                <div class="error_text mt-0" *ngIf="setPasswordForm.controls.confirmPassword.errors">
                                    Password and Confirm Password doesn’t match.</div>
                            </div>
                        </div>
                        <div class="register-footer-text mt-3 pl-3">
                            <span class="d-block mt-3">Hint: Your password should contain capital letters, numbers and special characters.</span>
                        </div>
                        <div class="login-button mt-3 text-center">
                           <div class="d-block"> <button class="jc-button " (click)="submitForm()">Submit</button></div>
                           <div class="d-block mt-3"> <button class="jc-btn-cancel" (click)="cancel()">Cancel</button> </div>
                        </div>

                    </div>

                </form>


            </div>
        </div>
    </div>
</div>