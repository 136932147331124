<div class="container-fluid tech-news p-relative">
    <span class="close-button" (click)="dialogRef.close()"><img src="assets/images/close.png" class="close-popup"
            alt="logo"></span>
    <div class="row">
        <div class="col">
            <div class="login-page mt-3">
                <div class="logo text-center">
                    <img src="assets/images/logo.png" class="mb-3" alt="logo">
                    <p>The search for your ideal job has come to an end! There are many different career paths to choose from. Look for a job that is a perfect fit for you.</p>
                </div>
                <div class="login-title text-center">
                    Login
                </div>
                <form [formGroup]="loginForm">

                    <div class="forms mt-2">

                        <div class="form-group popup-controls mb-3">
                            <label>Email ID<span class="text-danger">*</span></label>
                            <input type="text" class="form-control" placeholder="Enter email id"
                                formControlName="email"
                                (change)="getPassword()"
                                (blur)="getPassword()"
                                [ngClass]="{ 'is-invalid': 
                                (loginForm.controls.email.dirty && loginForm.controls.email.touched && loginForm.controls.email.errors) ||
                                (submitted && loginForm.controls.email.errors) ||
                                (showServerErrors && showServerErrors?.email) 
                            }">
                            <div class="error_text mt-0" *ngIf="(loginForm.controls.email.dirty && loginForm.controls.email.touched && loginForm.controls.email.errors) ||
                            (submitted && loginForm.controls.email.errors)">
                                <div class="error_text mt-0" *ngIf="loginForm.controls.email.errors && loginForm.controls.email.errors.pattern">Please
                                    enter
                                    a
                                    valid Email.
                                </div>
                                <div class="error_text mt-0" *ngIf="loginForm.controls.email.errors && loginForm.controls.email.errors.required">Email is
                                    required.</div>
                                </div>
                                <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.email">{{ showServerErrors.email }} </div>
                        </div>

                        <div class="form-group popup-controls mb-2 position-relative">
                            <label>Password<span class="text-danger">*</span> <img
                                    matTooltip="Password must be a minimum of 8 characters with at least 1 special character($,@,!,&,%,#), 1 number(0-9), 1 uppercase(A-Z) and 1 lowercase character(a-z)."
                                    matTooltipPosition="right" src="assets/images/information.svg" class="ml-1 password-info" alt="logo"></label>
                            <input [type]="hide ? 'password' : 'text'" class="form-control bg-img-none" placeholder="Enter Password"
                                formControlName="password"
                                [ngClass]="{ 'is-invalid': 
                                (loginForm.controls.password.dirty && loginForm.controls.password.touched && loginForm.controls.password.errors) ||
                                (submitted && loginForm.controls.password.errors) ||
                                (showServerErrors && showServerErrors?.password)
                            }">
                            <i class="eye-icon" (click)="hide = !hide" [ngClass]="(hide)?'fa fa-eye-slash':'fa fa-eye'"
                                aria-hidden="true"></i>
                            <div class="error_text mt-0" *ngIf="(loginForm.controls.password.dirty && loginForm.controls.password.touched && loginForm.controls.password.errors) ||
                            (submitted && loginForm.controls.password.errors)">
                                <div class="error_text mt-0" *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.required">
                                    Password
                                    is
                                    required.</div>
                                <div class="error_text mt-0" *ngIf="loginForm.controls.password.errors && loginForm.controls.password.errors.cannotContainSpace">
                                        Password cannot contain space. </div>
                                </div>
                                <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.password">{{ showServerErrors.password }} </div>
                        </div>

                        <div class="form-check mx-2 ml-14">
                            <input type="checkbox" class="form-check-input" id="exampleCheck" formControlName="remindMe">
                            <label class="form-check-label" for="exampleCheck" >Remember me</label>
                        </div>

                        <div class="login-button mt-3 text-center">
                            <button class="jc-button " (click)="loginSubmit()">Login</button>
                        </div>

                        <div class="login-footer-text mt-3">
                            <span class="d-block forgot-psw" (click)="showForgotPwdModel()">Forgot Password</span>
                            <span class="d-block mt-3">Dont have an account?
                                <span class="register" (click)="showRegisterModel()">Register Now</span>
                            </span>
                        </div>

                    </div>

                </form>
            </div>

        </div>
    </div>
</div>