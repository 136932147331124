
 <input type="file" [multiple]="multiple" #myFileInput class="" id="Image{{id}}" (change)="onSelectFilebase($event)"
 hidden>

<div [ngSwitch]="type">
    <div *ngSwitchDefault> 
        <button class="jc-button-small my-2" (click)="myFileInput.click()">Upload</button>
    </div>
    <div *ngSwitchCase="'card'">
        <div *ngIf="fileList.length">
            <div class="resume-uplod file-uploaded" *ngFor="let file of fileList; let i = index" (click)="openFile(file)">
                <div class="d-flex">
                    <img src="assets/images/{{ getFileExtension(file.name) }}.svg" class="mb-2 mt-2" alt="logo">
    
                <span class="file-name"> {{ file.name }} </span>
                </div>
                <span class="ml-3 cursor-p" (click)="clearFile(i)"><img
                        src="assets/images/close.png" class="close-popup" alt="logo"></span>
            </div>
        </div>
        <div class="file-upload cursor-p float-left" *ngIf="!fileList.length">
            <div class="image-upload-wrap" (click)="myFileInput.click()">
                <div class="drag-text">
                    <img src="assets/images/upload-coverletter.png" class="mb-2 mt-2" alt="logo">
                    <h3> <span  > {{ fileUploadMessage }} </span></h3>
                    <h3 class="format">Format: <span *ngFor="let ext of fileExtension"> .{{ ext }} </span> within ( 2Mb )</h3>
                </div>
            </div>
        </div>
    </div>
  </div>