<div class="recommended-jobs-title mb-2">Recommended Jobs</div>
<div class="card recommended-jobs mb-2" *ngFor="let data of recommendedJobsData">
    <div class="row">
        <div class="col-8 col-md-8">
            <div class="job-title d-block cursor-p" (click)="showDetails(data)"> {{ data?.jobTitle ? data?.jobTitle : 'N/A' }} </div>
            <div class="company-name d-block mb-2"> {{ data?.companyName ? data?.companyName : 'N/A' }} </div>
            <div class="experience d-block mb-2">
                <div class="d-inline-block mr-3 icon-div">
                    <img src="assets/images/experience_icon.svg" alt="location">
                </div>  
                    <span class="span-middle" *ngIf="(data?.minExperience != undefined && data?.maxExperience != undefined); else experienceTemp"> {{
                        (data.minExperience != undefined) ? data.minExperience : 'N/A' }} - {{ (data.maxExperience != undefined) ?
                        data.maxExperience : 'N/A' }}
                        Years</span>
                    <span>
                        <ng-template #experienceTemp>
                            <span class="span-middle">N/A </span> 
                        </ng-template>
                    </span>
            </div>
            <div class="experience d-block mb-2">
                <div class="d-inline-block mr-3 icon-div">
                    <img src="assets/images/employment_type.svg" alt="location"> 
                </div>
                <span class="span-middle">{{ data?.employeementTypeName ? data?.employeementTypeName : 'N/A' }}</span>  
            </div>

            <div class="experience d-block">
                <div class="d-inline-block mr-3 icon-div">
                    <img class="loc-icon" src="assets/images/location.svg" alt="location">
                </div> 
                <span class="span-middle">{{ data?.location ? data?.location : 'N/A' }}</span> 
            </div>
        </div>
        <div class="col-4 col-md-4">
            <div class="d-block user-icon-section"> 
                <img
                    src="{{ data?.companyLogoPath ? appService.domain + data?.companyLogoPath : 'assets/images/group42516.svg' }}"
                    class="mr-3 user-icon" alt="..." style="width: 100%;">
                    <div class="experience d-block mt-2"> Job Code: {{ data?.jobCode ? data?.jobCode : 'N/A' }} </div>
            </div>
        </div>
        <!-- <div class="col-md-12">
            <div class="experience d-block">
                <div class="d-inline-block mr-3 icon-div">
                    <img class="loc-icon" src="assets/images/location.svg" alt="location">
                </div> 
                <span class="span-middle">{{ data?.location ? data?.location : 'N/A' }}</span> 
            </div>
        </div> -->
    </div>
    <!-- <div class="row mt-3" *ngIf="showApplyButton">
        <div class="col d-flex align-i-center justify-content-between">
            <div class=""><button class="last-update-btn"><img src="assets/images/postedicon.svg" class="mr-2" alt="location"> 15 mins ago</button></div>
            <div class=""><img src="assets/images/share_circle.svg" class="pl-2 ml-3" alt="location"></div>
            <div class=""><button class="jc-button apply-btn">Apply</button></div>
        </div>
    </div> -->
</div>
