import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpMethod } from '../../../core/enums/http-handlers';
import { AppService } from '../../../core/services/app.service';
import { CommonService } from '../../../core/services/common.service';
import { EmployeeService } from '../../../core/services/employee.service';
import { SearchJobsService } from '../../../core/services/search-jobs.service';
import { SpinnerService } from '../../../core/services/spinner.service';

@Component({
  selector: 'app-common-job-alert',
  templateUrl: './common-job-alert.component.html',
  styleUrls: ['./common-job-alert.component.scss']
})
export class CommonJobAlertComponent implements OnInit {

  createAlertForm: FormGroup;
  submitted = false;
  // show server error
  showServerErrors = {
    jobalert: ''
  }

  constructor(
    private fb: FormBuilder,
    public commonService: CommonService,
    public appService: AppService,
    private spinnerService: SpinnerService,
    public searchJobsService: SearchJobsService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.searchJobsService.getJobSearchDropdownData()
    this.createAlertForm = this.fb.group({
      jobAlertName: ['', Validators.compose([Validators.required ,Validators.pattern('[a-zA-Z][a-zA-Z ]+')])],
      jobTitle: ['', Validators.required],
      employeeId: [loginData.id],
    });
    this.createAlertForm.get('jobAlertName').valueChanges.subscribe(res => {
      this.showServerErrors ? this.showServerErrors.jobalert = '': null
    })
  }
  
  clearValue(key) {
    this.createAlertForm.patchValue({
      [key]: ''
    })
  }
  
  setValues(value, key, id?) {
    this.createAlertForm.patchValue({
      [key]: id ? value[id] : value
    })
  }

  
  submitCreateAlert() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    this.createAlertForm.patchValue({
      employeeId: loginData.id
    })
    this.submitted = true;
    if (this.createAlertForm.invalid) {
      return
    }
    const url = `${this.appService.createEmployeeJobAlert}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.POST,
      requestObj: this.createAlertForm.value
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag === 'showControlError') {
        this.showServerErrors = res.data;
      } else if (statusFlag) {
        this.submitted = false;
        this.createAlertForm.reset();
        this.employeeService.getNavigationCount();
      }
    });
  }

}
