import { Component, OnInit, Input, ViewChild, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CommonService } from '../../../core/services/common.service';
import { YesOrNoComponent } from '../yes-or-no/yes-or-no.component';
import { AppService } from '../../../core/services/app.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  // table props
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<any>;
  columnDefinitions = [];
  tableObj: any;
  filterTable = '';
  @Input() allChecked = false;

  @Output() onLinkClickEmit = new EventEmitter();
  @Output() onCheckboxClickEmit = new EventEmitter();
  @Output() onDropdownClickEmit = new EventEmitter();
  @Output() onViewRowEmit = new EventEmitter();
  @Output() onEditRowEmit = new EventEmitter();
  @Output() onDeleteRowEmit = new EventEmitter();
  @Output() onMailRowEmit = new EventEmitter();
  @Output() onCopyRowEmit = new EventEmitter();
  @Output() emitPaginationEmit = new EventEmitter();

  @Input() set tableData(res) {
    if(res == 'empty') {
      this.defaultValues();
      return;
    }
    this.defaultValues();
    if (!this.commonService.checkNullOrUndefined(res)) {
      this.tableObj = res;
      this.tableDataFunc(res)
    }
  }

  @Input() showHeader = true;
  @Input() showShearchHeader = false;
  @Input() shearchHeader = 'List';
  @Input() showCheckAll = true;
  @Input() totalTableLength = 0;
  @Input() pageSize = 10;

  defaultValues() {
    this.allChecked = false;
    this.filterTable = '';
    this.tableObj = null;
    this.dataSource = new MatTableDataSource();
    this.columnDefinitions = [];
  }

  constructor(public commonService: CommonService, public appService: AppService) { }

  ngOnInit() {
  }


  reset() {
  }

  getPaginationData(event) {
    this.emitPaginationEmit.emit(event)
  }


  doFilter = (value) => {
    this.dataSource.filter = value.target.value.trim().toLocaleLowerCase();
    this.allChecked = false;
  }

  tableDataFunc(data) {
    this.dataSource = new MatTableDataSource();

    if (!this.commonService.checkNullOrUndefined(data.list)) {
      if (data.list.length) {
        this.tableDataUpdate(data.list);
      }
    }

    if (!this.commonService.checkNullOrUndefined(data.list) && data.list.length) {

      this.columnDefinitions = [];

      data.columns.forEach(cols => {
        const obj = { 
          def: cols.key, 
          label: cols.label, 
          type: cols.type ? cols.type : '', 
          hide: cols.hide ? cols.hide : false,
          ...cols
        }
        this.columnDefinitions.push(obj)
      });

    }

    // this.dataSource.filterPredicate = function(resp, filter: string): boolean {
    //   return resp.name.toLowerCase().includes(filter) || resp.symbol.toLowerCase().includes(filter) || resp.position.toString() === filter;
    // };

  }

  displayLable(col) {
    return this.columnDefinitions.find(res => res.def == col)
  }

  displayDropdown(data, val) {
    const obj = data.lists.find(res => res[data.id] === val)
    return obj ? obj[data.text] : ''
  }

  onLink(ele, column) {
      let obj = { ele: ele, col: column  }
      this.onLinkClickEmit.emit(obj);
  }


  checkAll(event) {
    this.allChecked = true;
    const checked = event.target.checked;
    let list = [ ...this.dataSource.data ]; 
    list.map(res =>{ 
       if(this.dataSource.filteredData.some(resp => resp[this.tableObj.uniqueKey] === res[this.tableObj.uniqueKey])) {
            res.checked = checked  ? true : false 
       }})
    const obj = { value: checked, element: 'all', data: checked ? list : [] };
    this.onCheckboxClickEmit.emit(obj);
    // this.tableDataUpdate(list);
  }

  checkboxClick(event,element) {
    const checked = event.target.checked;
    let list = [ ...this.dataSource.data ];
    list.map(res =>  res.checked = res[this.tableObj.uniqueKey] === element[this.tableObj.uniqueKey] ? checked ? true : false  : res.checked )
    const obj = { value: checked, element: element, data: list };
    this.onCheckboxClickEmit.emit(obj);
    // this.tableDataUpdate(list);
  }

  tableDataUpdate(data) {
    this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // this.filterTable = '';
  }

  

  dropdownClick(value,column,element, name) {
    this.confirmationPopup(name, (flag) => {
      if(flag) {
        const obj = { value: value, element: element, data: this.dataSource.data };
        this.onDropdownClickEmit.emit(obj);
        let list = [ ...this.dataSource.data ];
        list.map(res => res[column] = res[this.tableObj.uniqueKey] === element[this.tableObj.uniqueKey] ? value  : res[column] )
        this.tableDataUpdate(list);
      } else {
        // let list = [ ...this.dataSource.data ];
        // this.dataSource = new MatTableDataSource()
        // this.tableDataUpdate(list);
      }
    })
  }

  onMailRow(row) {
    this.onMailRowEmit.emit(row)
  }

  onViewRow(row) {
    this.onViewRowEmit.emit(row)
  }

  onEditRow(row) {
    this.onEditRowEmit.emit(row)
  }

  onDeleteRow(row) {
    this.onDeleteRowEmit.emit(row)
  }

  onCopyRow(row) {
    this.onCopyRowEmit.emit(row)
  }

  confirmationPopup(value,callBack) {
    const obj = {
      template: YesOrNoComponent,
      data: {
        description: `Do you want to <span class='in-active-text'>${value}</span> the job status`,
        yes: `Yes! ${value}`,
        buttonClass: `${value}-btn`
      }
    }
    this.commonService.openDialog(obj, (res) => {
        callBack(res)
    })
  }

  getDisplayedColumns(): string[] {
    if (this.dataSource && this.dataSource.data && !this.commonService.checkNullOrUndefined(this.dataSource.data)) {
      return this.columnDefinitions.filter(cd => !cd.hide).map(cd => cd.def);
    }
  }

  selectRow(row, index) {
    console.log(row, index)
  }

  showMatToolip(event) {
    if(event && event.length >= 14) {
      return event
    } else {
      return null
    }
  }
}
