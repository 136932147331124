import { SpinnerService } from './spinner.service';
import { CommonService } from './common.service';
import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { HttpMethod } from '../enums/http-handlers';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {


  jobAlertNotifySubject = new BehaviorSubject(1);
  totalNotificationCount: any;
  jobscountBaedonAlerts: any[];
  profileBasedJob: any;
  profilePicPath = '';
  gender = '';
  
  setAlertNotifySubject(event) {
    this.jobAlertNotifySubject.next(event)
  }



  constructor(private appService: AppService,
    private commonService: CommonService,
    private spinnerService: SpinnerService) { }

  getNavigationCount() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if(!loginData) {
      return;
    }
    this.totalNotificationCount = 0;
    this.jobscountBaedonAlerts = [];
    let profileBasedJobCount = 0;
    this.profileBasedJob;
    const apiObj = {
      url: `${this.appService.getNavigationCount}?EmployeeId=${loginData.id}`,
      methodType: HttpMethod.GET,
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag) {
        if (res.data.hasOwnProperty('jobscountbasedonprofile') && Object.keys(res.data.jobscountbasedonprofile).length) {
          profileBasedJobCount = 1;
          this.profileBasedJob = res.data.jobscountbasedonprofile
        }
        if (res.data.jobscountBaedonAlerts && res.data.jobscountBaedonAlerts.length) {
          this.jobscountBaedonAlerts = res.data.jobscountBaedonAlerts;
        }
        this.totalNotificationCount = this.jobscountBaedonAlerts.length + profileBasedJobCount;
      }
    }
    )
  }

}
