import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private http: HttpClient, private appService: AppService, private router: Router) {
  }

  commonPostHandler(url: string, data: any): Observable<any> {
    return this.http.post(url, data, { headers: this.getToken() });
  }

  commonGetHandler(url: string): Observable<any> {
    return this.http.get(url, { headers: this.getToken() });
  }

  commonGetByHandler(url: string): Observable<any> {
    return this.http.get(url, { headers: this.getToken() });
  }

  commonPutHandler(url: string, data: any): Observable<any> {
    return this.http.put(url, data, { headers: this.getToken() });
  }

  commonDeleteHandler(url: string): Observable<any> {
    return this.http.delete(url, { headers: this.getToken() });
  }

  commonGetByWithoutHandler(url: string, data): Observable<any> {
    return this.http.get(url, { headers: data });
  }

  getToken(): any {
    return 'Authorization:Bearer ' + localStorage.getItem('auth-token');
  }

  refreshToken(token) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };    
    const url = `${this.appService.refreshToken}?refreshToken=${token}`;
    return this.http.get(url, httpOptions);
  }


}
