import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpMethod } from '../../../../core/enums/http-handlers';
import { AppService } from '../../../../core/services/app.service';
import { CommonService } from '../../../../core/services/common.service';
import { SpinnerService } from '../../../../core/services/spinner.service';

@Component({
  selector: 'app-recommended-jobs',
  templateUrl: './recommended-jobs.component.html',
  styleUrls: ['./recommended-jobs.component.scss']
})
export class RecommendedJobsComponent implements OnInit {

  @Input() showApplyButton = true;
  recommendedJobsData = []

  constructor(
    public commonService: CommonService,
    public appService: AppService,
    private spinnerService: SpinnerService,
    private route: Router,

  ) { }

  ngOnInit(): void {
    this.recommendedJobs()
  }

  recommendedJobs() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if(loginData) {
      const url = `${this.appService.recommendedJobs}?EmployeeId=${loginData.id}`;
      const apiObj = {
        url: url,
        methodType: HttpMethod.GET
      }
      this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
        this.spinnerService.hide();
        this
        if (statusFlag && res && res.hasOwnProperty('data')) {
          this.recommendedJobsData = res.data;
        }
      }));
    }
  }
  
  showDetails(job) {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if(loginData && loginData.id) {
      this.route.navigate(['auth/employee/search-jobs/job-details', job.jobId]);
    }
  }


}
