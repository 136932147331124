import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor() { }


  domain = 'https://api2.job-clicks.com/';

  refreshToken = `${this.domain}api/login/RefreshToken`;

  loginEmployee = `${this.domain}api/Login/employee`;
  empRegister = `${this.domain}api/registration/employee`;
  employeeVerifyEmail = `${this.domain}api/registration/EmployeeVerifyEmail`;
  employeeForgotPwd = `${this.domain}api/registration/EmployeeForgotPassword`;
  uploadResumeAndCoverLater = `${this.domain}api/employee/UploadResumeAndCoverLater`;

  loginEmployer = `${this.domain}api/Login/employer`;
  employerRegister = `${this.domain}api/registration/employer`;
  employerVerifyEmail = `${this.domain}api/registration/EmployerVerifyEmail`;
  employerForgotPwd = `${this.domain}api/registration/EmployerForgotPassword`;
  resetPassword = `${this.domain}api/registration/ResetPassword`;


  resendVerification = `${this.domain}api/registration/ResendEmailVerification`;
  verifyOTP = `${this.domain}api/registration/VerifyOTP`;
  resendOTP = `${this.domain}api/registration/ResendOTP`;

  // company profile
  saveEmployerDetails = `${this.domain}api/employer/SaveEmployerDetails`;
  savejobdata = `${this.domain}api/employer/SaveJobDetails`;
  getCountrys = `${this.domain}api/employee/GetCountrys`;
  getStatesByCountryID = `${this.domain}api/employee/GetStatesByCountryID`;
  getCitiesByStateID = `${this.domain}api/employee/GetCitiesByStateID`;
  getalldropdownlist = `${this.domain}api/employee/GetEmployeeProfileDropdownData`;
  getalldropdownlistpostjobs = `${this.domain}api/employer/GetEmployerPostJobsDropdownData`;
  getJobDetailsEmpByJobId = `${this.domain}api/employer/GetJobDetailsByJobId`;
  getEmployerDetails = `${this.domain}api/employer/GetEmployerDetails`;
  updateEmployerDetails = `${this.domain}api/employer/UpdateEmployerDetails`;
  updatejobdeatils = `${this.domain}api/employer/UpdateJobDetails`;
  updateEmployerLogo = `${this.domain}api/employer/UpdateEmployerLogo`;
  saveAndUpdateEmployerRecruiter = `${this.domain}api/employer/SaveAndUpdateEmployerRecruiter`;
  updateJobCompanyDetails = `${this.domain}api/employer/UpdateJobCompanyDetails`;
  updateEmployerContactPersonData = `${this.domain}api/employer/UpdateEmployerContactPersonData`;


  //employer search
  getEmployerJobSearchDropdownData = `${this.domain}api/Employer/GetEmployeeSearchDropdownData`;
  getCandidatesSearchResult = `${this.domain}api/employer/GetCandidatesSearchResult`;
  updateEmployerComments = `${this.domain}api/employer/UpdateEmployerComments`;
  removeEmployerComments = `${this.domain}api/employer/RemoveEmployerComments`;
  updateEmployerCallStatus = `${this.domain}api/employer/UpdateEmployerCallStatus`;
  updateEmployerProfileStatus = `${this.domain}api/employer/UpdateEmployerProfileStatus`;
  getRecentAndSavedSearchNames = `${this.domain}api/Employer/GetRecentAndSavedSearchNames`;
  getSearchDetailsBasedOnSearchId = `${this.domain}api/Employer/GetSearchDetailsBasedOnSearchId`;
  
  // employee details
  getEmployeeDetailsBasedOnEmployeeId = `${this.domain}api/Employer/GetEmployeeDetailsBasedOnEmployeeId`;
  viewEmployeeContactNumber = `${this.domain}api/Employer/ViewEmployeeContactNumber`;

  // saved profiles
  createNewJobCatagory = `${this.domain}api/Employer/CreateNewJobCatagory`;
  updateJobCatagory = `${this.domain}api/Employer/UpdateJobCatagory`;
  getAllJobCatagorys = `${this.domain}api/Employer/GetAllJobCatagorys`;
  deleteJobCatagory = `${this.domain}api/Employer/DeleteJobCatagory`;
  saveprofilesinJobcategory = `${this.domain}api/Employer/SaveprofilesinJobcategory`;
  getEmployeeSavedprofilesbasedonJobcategoryId = `${this.domain}api/Employer/GetEmployeeSavedprofilesbasedonJobcategoryId`;
  getallSavedResumesData = `${this.domain}api/Employer/GetallSavedResumesData`;
  updateSavedResumesClientandCommentsData = `${this.domain}api/Employer/UpdateSavedResumesClientandCommentsData`;

  // manage application recevied
  getManageApplicationDetails = `${this.domain}api/Employer/GetManageApplicationDetails`;
  updateEmployeeJobStatus = `${this.domain}api/Employer/UpdateEmployeeJobStatus`;

  // saved and receent search
  getMoreRecentAndSavedSearchDetails = `${this.domain}api/Employer/GetMoreRecentAndSavedSearchDetails`;
  deleteSearchDetails = `${this.domain}api/Employer/DeleteSearchDetails`;
  
  // resume reminders
  saveAndUpdateEmployerReminders = `${this.domain}api/Employer/SaveAndUpdateEmployerReminders`;
  getEmployerAllReminders = `${this.domain}api/Employer/GetEmployerAllReminders`;
  getEmployerRemindersCount = `${this.domain}api/Employer/GetEmployerRemindersCount`;
  deleteReminders = `${this.domain}api/Employer/DeleteReminders`;
  deleteSavedResumeDetails = `${this.domain}api/employer/DeleteSavedResumeDetails`;



  // ***************************************** employee ************************************//

  // myprofile
  getEmployeeDatabyUserID = `${this.domain}api/employee/GetEmployeeDatabyUserID`;
  saveEmployeeDetails = `${this.domain}api/employee/CreateEmployeeProfile`;
  removeEmployeeEducation = `${this.domain}api/employee/RemoveEmployeeEducation`;
  removeEmployeeSkill = `${this.domain}api/employee/RemoveEmployeeSkill`;
  removeEmployeeWorkExperience = `${this.domain}api/employee/RemoveEmployeeWorkExperience`;
  removeEmployeeCertification = `${this.domain}api/employee/RemoveEmployeeCertification`;

  updateEmployeedata = `${this.domain}api/employee/UpdateEmployeedata`;
  updateEmployeeJobInformationdata = `${this.domain}api/employee/UpdateEmployeeJobInformationdata`;
  saveEmployeeSkillsData = `${this.domain}api/employee/SaveEmployeeSkillsData`;
  saveEmployeeEducationData = `${this.domain}api/employee/SaveEmployeeEducationData`;
  saveEmployeeWorkExperienceData = `${this.domain}api/employee/SaveEmployeeWorkExperienceData`;
  updateEmployeeKnowOtherSkills = `${this.domain}api/employee/UpdateEmployeeKnowOtherSkills`;

  //notification
  getNavigationCount = `${this.domain}api/employee/GetNavigationCount`;

  updateEmployeeFiles = `${this.domain}api/employee/UpdateEmployeeFiles`;
  removeEmployeeFiles = `${this.domain}api/employee/RemoveEmployeeFiles`;
  saveEmployeeCertificationData = `${this.domain}api/employee/SaveEmployeeCertificationData`;

  // verify email
  verifyEmployeeEmail = `${this.domain}api/employee/VerifyEmployeeEmail`;
  updateEmployeeEmail = `${this.domain}api/employee/UpdateEmployeeEmail`;

  // verify phone
  updateMobileNumber = `${this.domain}api/employee/UpdateMobileNumber`;
  // search jobs
  // getAllSkills = `${this.domain}api/employee/GetAllSkills`;
  getAllJobTitles = `${this.domain}api/employee/GetAllJobTitles`;

  getAllCitieandStates = `${this.domain}api/employee/GetAllCitieandStates`;
  getAllJobTitleandSkills = `${this.domain}api/employee/GetAllJobTitleandSkills`;

  getJobSearchDropdownData = `${this.domain}api/employee/GetJobSearchDropdownData`;
  searchJobs = `${this.domain}api/employee/SearchJobs`;
  getJobDetailsbyJobId = `${this.domain}api/employee/GetJobDetailsbyJobId`;
  skipAndApplyPosition = `${this.domain}api/employee/SkipAndApplyPosition`;
  applyJobsinEmployee = `${this.domain}api/employee/ApplyJobsinEmployee`;
  getAllApplyedJobDetails = `${this.domain}api/employee/GetAllApplyedJobDetails`;
  getAllSavedJobDetails = `${this.domain}api/employee/GetAllSavedJobDetails`;

  // manage jobs
  getAllEmployeeJobAlerts = `${this.domain}api/employee/GetAllEmployeeJobAlerts`;
  createEmployeeJobAlert = `${this.domain}api/employee/CreateEmployeeJobAlert`;
  updateEmployeeJobAlert = `${this.domain}api/employee/UpdateEmployeeJobAlert`;
  deleteSelectedEmployeeJobAlert = `${this.domain}api/employee/DeleteSelectedEmployeeJobAlert`;

  // job alerts
  getJobsBasedonJobAlertbyEmployeeID = `${this.domain}api/employee/GetJobsBasedonJobAlertbyEmployeeID`;
  getJobsBasedEmployeeProfile = `${this.domain}api/employee/GetJobsBasedEmployeeProfile`;

  // send mail
  sendJobInformationToEmails = `${this.domain}api/employee/SendJobInformationToEmails`;

  // ***************************************** employer ************************************//

  // manage jobs
  getAllJobDetails = `${this.domain}api/employer/GetAllJobDetails`;
  updateJobStatus = `${this.domain}api/employer/UpdateJobStatus`;
  getJobDetailsByJobId = `${this.domain}api/employer/GetJobDetailsByJobId`;

  // verify email
  verifyEmployerEmail = `${this.domain}api/employer/VerifyEmployerEmail`;
  updateEmployerEmail = `${this.domain}api/employer/UpdateEmployerEmail`;
  saveEmployeeJobs = `${this.domain}api/employee/SaveEmployeeJobs`;

  // verify phone
  updateEmployerMobileNumber = `${this.domain}api/employer/UpdateMobileNumber`;

  // vendors
  getAllVendorDetails = `${this.domain}api/employer/GetAllVendorDetails`;
  saveVendorDetails = `${this.domain}api/employer/SaveVendorDetails`;
  updateVendorDetails = `${this.domain}api/employer/UpdateVendorDetails`;
  saveAndUpdateVendorContactDetails = `${this.domain}api/employer/SaveAndUpdateVendorContactDetails`;
  saveAndUpdateVendorEmployeeDetails = `${this.domain}api/employer/SaveAndUpdateVendorEmployeeDetails`;
  updateVendorDocuments = `${this.domain}api/employer/UpdateVendorDocuments`;
  deleteVendorContact = `${this.domain}api/employer/DeleteVendorContact`;
  deleteVendorEmployee = `${this.domain}api/employer/DeleteVendorEmployee`;
  updateVendorStatus = `${this.domain}api/employer/UpdateVendorStatus`;
  getVenderTypeMasterData = `${this.domain}api/employer/GetVenderTypeMasterData`;

  getVendorDetailsByVendorID = `${this.domain}api/employer/GetVendorDetailsByVendorID`;
  
  // vendors
  getAllClientDetails = `${this.domain}api/employer/GetAllClientDetails`;
  saveClientDetails = `${this.domain}api/employer/SaveClientDetails`;
  updateClientDetails = `${this.domain}api/employer/UpdateClientDetails`;
  saveAndUpdateClientContactDetails = `${this.domain}api/employer/SaveAndUpdateClientContactDetails`;
  saveAndUpdateClientEmployeeDetails = `${this.domain}api/employer/SaveAndUpdateClientEmployeeDetails`;
  updateClientDocuments = `${this.domain}api/employer/UpdateClientDocuments`;
  deleteClientContact = `${this.domain}api/employer/DeleteClientContact`;
  deleteClientEmployee = `${this.domain}api/employer/DeleteClientEmployee`;
  updateClientStatus = `${this.domain}api/employer/UpdateClientStatus`;

  getClientDetailsByClientID = `${this.domain}api/employer/GetClientDetailsByClientID`;
  
  // users
  getEmployerPreDefinedUserResponsibilities = `${this.domain}api/employer/GetEmployerPreDefinedUserResponsibilities`;
  addEmployeeSubUsers = `${this.domain}api/employer/AddEmployeeSubUsers`;
  getAllEmployeeSubUsers = `${this.domain}api/employer/GetAllEmployeeSubUsers`;
  getEmployeeSubUserDetailsBasedOnUserId = `${this.domain}api/employer/GetEmployeeSubUserDetailsBasedOnUserId`;
  updateEmployerSubUsers = `${this.domain}api/employer/UpdateEmployerSubUsers`;
  updateSubUserStatus = `${this.domain}api/employer/UpdateSubUserStatus`;  
  setPasswordThroughLink = `${this.domain}api/employer/SetPasswordThroughLink`;  
  
  getEmployerUserResponsibilities = `${this.domain}api/employer/GetEmployerUserResponsibilities`;
  saveUserProfileDetails = `${this.domain}api/employer/SaveUserProfileDetails`;
  getUserProfileDetails = `${this.domain}api/employer/GetUserProfileDetails`;
  updateUserProfileDetails = `${this.domain}api/employer/UpdateUserProfileDetails`;
  updateUserContactDetails = `${this.domain}api/employer/UpdateUserContactDetails`;
  updateUserProfilePicture = `${this.domain}api/employer/UpdateUserProfilePicture`;

  getAllJobDraftDetails = `${this.domain}api/employer/GetAllJobDraftDetails`;
  deleteJobDraftDetails = `${this.domain}api/employer/DeleteJobDraftDetails`;
  draftJobDetails = `${this.domain}api/employer/DraftJobDetails`;

  // employer dashboard
  employerDashboardCount = `${this.domain}api/employer/EmployerDashboardCount`;
  latestActivePostjobs = `${this.domain}api/employer/LatestActivePostjobs`;
  getAllEmployerUsersData = `${this.domain}api/employer/GetAllEmployerUsersData`;
  getAllRecevidApplicationGraphData = `${this.domain}api/employer/GetAllRecevidApplicationGraphData`;
  getAllGraphDataByCustomDate = `${this.domain}api/employer/GetAllGraphDataByCustomDate`;
  getAllEmployerUserPostedJobs = `${this.domain}api/employer/GetAllEmployerUserPostedJobs`;

  // employee dashboard
  employeeDashboardCount = `${this.domain}api/employee/EmployeeDashboardCount`;
  recommendedJobs = `${this.domain}api/employee/RecommendedJobs`;
  deleteEmployeeSavedJobs = `${this.domain}api/employee/DeleteEmployeeSavedJobs`;


  // employer reports
  getAllEmployerUserReport = `${this.domain}api/employer/GetAllEmployerUserReport`;
  getUsersPostJobGraphData = `${this.domain}api/employer/GetUsersPostJobGraphData`;
  getPostJobGraphDataByCustomDate = `${this.domain}api/employer/GetPostJobGraphDataByCustomDate`;
  
  // Recuriter dashboard
  getAllClientDetailsbyRecuriter = `${this.domain}api/employer/GetAllClientDetailsbyRecuriter`;
  getAllVendorDetailsbyRecuriter = `${this.domain}api/employer/getAllVendorDetailsbyRecuriter`;
  
  getAllUserMassEmailinformation = `${this.domain}api/employer/GetAllUserMassEmailinformation`;
  getAllEmployerPostedJobs = `${this.domain}api/employer/GetAllEmployerPostedJobs`;
  getAllUserProfileviewinformation = `${this.domain}api/employer/GetAllUserProfileviewinformation`;

  getUserAndJobStatusCodes = `${this.domain}api/employee/GetUserAndJobStatusCodes`;
  resendEmailVerification = `${this.domain}api/registration/ResendEmailVerification`;

  removeEmployerUserProfilePic = `${this.domain}api/employer/RemoveEmployerUserProfilePic`;
  removeEmployerLogo = `${this.domain}api/employer/RemoveEmployerLogo`;
  
  getAllEmployerUserResponsibilities = `${this.domain}api/employer/GetAllEmployerUserResponsibilities`;
  updateEmployerSubUsersResponsibilities = `${this.domain}api/employer/UpdateEmployerSubUsersResponsibilities`;
  updateEmployerSubUsersQuota = `${this.domain}api/employer/UpdateEmployerSubUsersQuota`;
  removeClientDocuments = `${this.domain}api/employer/RemoveClientDocuments`;
  removeVendorDocuments = `${this.domain}api/employer/RemoveVendorDocuments`;

  getAllActiveIndustries = `${this.domain}api/employer/GetAllActiveIndustries`;
  sharejobstoApplicants = `${this.domain}api/employer/SharejobstoApplicants`;
  getAllBlogDetails = `${this.domain}api/admin/GetAllBlogDetails`;
  getBlogCategoriesWithBlogsCount = `${this.domain}api/admin/GetBlogCategoriesWithBlogsCount`;
  saveContactDetails = `${this.domain}api/registration/SaveContactDetails`;

  getReceivedApplicantsNotificationDetails = `${this.domain}api/Employer/GetReceivedApplicantsNotificationDetails`;
  
}
