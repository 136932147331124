<div class="container-fluid tech-news p-relative">
    <span class="close-button" (click)="dialogRef.close()"><img src="assets/images/close.png" class="close-popup"
            alt="logo"></span>
    <div class="row">
        <div class="col">
            <!-- Forgot password page section start -->
            <div class="login-page mt-3">
                <div class="logo text-center">
                    <img src="assets/images/logo.png" class="mb-3" alt="...">
                    <p>The search for your ideal job has come to an end! There are many different career paths to choose from. Look for a job that is a perfect fit for you.</p>
                </div>
                <form [formGroup]="forgotForm" *ngIf="showForgotSection">

                    <div class="login-title text-center">
                        Forgot Password
                    </div>

                    <div class="forms mt-2">
                        <div class="form-group popup-controls mb-3">
                            <label> Enter your registered Email ID<span class="text-danger">*</span></label>
                            <input type="text" id="forgotEmail" class="form-control" formControlName="Email"
                                placeholder="Email Address"
                                [ngClass]="{ 'is-invalid': 
                                (forgotForm.controls.Email.dirty && forgotForm.controls.Email.touched && forgotForm.controls.Email.errors) ||
                                (submitted && forgotForm.controls.Email.errors) ||
                                (showServerErrors && showServerErrors?.email)
                             }"  >
                            <div class="error_text mt-0" *ngIf="(forgotForm.controls.Email.dirty && forgotForm.controls.Email.touched && forgotForm.controls.Email.errors) ||
                            (submitted && forgotForm.controls.Email.errors)">
                                <div class="error_text mt-0" *ngIf="forgotForm.controls.Email.errors && forgotForm.controls.Email.errors.pattern">
                                    Please enter a valid Email. </div>
                                <div class="error_text mt-0 " *ngIf="forgotForm.controls.Email.errors && forgotForm.controls.Email.errors.required">
                                    Email is required.
                                </div>
                            </div>
                            <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.email">{{ showServerErrors.email }} </div>
                        </div>
                        <div class="login-button mt-4 text-center">
                            <button class="jc-button " (click)="onforgotpassword()">Get Verification Code</button>
                        </div>
                        <div class="login-footer-text mt-3">
                            <span class="d-block mt-3">Do you have an account?
                                <span class="register" (click)="showLoginModel()">Login</span>
                            </span>
                            <span class="d-block mt-3">Don't have an account?
                                <span class="register" (click)="showRegisterModel()">Register Now</span>
                            </span>
                        </div>
                    </div>

                </form>

                <form [formGroup]="forgotVerificationForm" *ngIf="showVerifictionSection">

                    <div class="login-title text-center">
                        Forgot Password
                    </div>

                    <div class="forms mt-2">
                        <div class="form-group popup-controls mb-3">
                            <label> Enter your registered Email ID<span class="text-danger">*</span></label>
                            <input type="text" id="confirmEmail" class="form-control" formControlName="Email"
                                placeholder="Email Address">
                        </div>
                        <div class="login-title text-center mb-2">
                            Verification Code
                        </div>
                        <div class="form-group popup-controls mb-3">
                            <label> Enter the code that was sent to your Email ID<span
                                    class="text-danger">*</span></label>
                            <input type="text" id="verificationCode" class="form-control"
                                formControlName="Verificationcode" placeholder="Verification Code"
                                (keypress)="commonService.numberOnly($event)" maxlength="6"
                                [ngClass]="{ 'is-invalid': 
                                (forgotVerificationForm.controls.Verificationcode.dirty && forgotVerificationForm.controls.Verificationcode.touched && forgotVerificationForm.controls.Verificationcode.errors) ||
                                (submitted && forgotVerificationForm.controls.Verificationcode.errors) ||
                                (showServerErrors && showServerErrors?.otp)
                             }">
                            <div class="error_text mt-0"
                                *ngIf="(forgotVerificationForm.controls.Verificationcode.dirty && forgotVerificationForm.controls.Verificationcode.touched && forgotVerificationForm.controls.Verificationcode.errors) ||
                                (submitted && forgotVerificationForm.controls.Verificationcode.errors)">
                                <div class="error_text mt-0"
                                    *ngIf="forgotVerificationForm.controls.Verificationcode.errors">
                                    Please enter valid 6 digit verification number.
                                </div>
                            </div>
                            <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.otp">{{ showServerErrors.otp }} </div>
                        </div>
                        <div class="login-button mt-4 text-center">
                            <button class="jc-button " (click)="confirmCode()">Confirm Code</button>
                        </div>
                        <div class="login-footer-text mt-3">
                            <span class="d-block mt-3">Don't receive the code?
                                <span class="register cursor-p" (click)="resendOTP()">Resend</span>
                            </span>
                        </div>
                    </div>

                </form>

                <form [formGroup]="resetPasswordForm" *ngIf="showResetPasswordSection">

                    <div class="login-title text-center">
                        Reset Password
                    </div>
                    <div class="form-group popup-controls mb-2 position-relative">
                        <label> New Password<span class="text-danger">*</span><img src="assets/images/information.svg"
                            matTooltip="Password must be a minimum of 8 characters with at least 1 special character($,@,!,&,%,#), 1 number(0-9), 1 uppercase(A-Z) and 1 lowercase character(a-z)."
                            matTooltipPosition="right"  class="ml-1 password-info" alt="logo"></label>
                        <input [type]="hide ? 'password' : 'text'" placeholder="New Password" class="form-control bg-img-none"
                            minlength="8"
                            [ngClass]="{ 'is-invalid': 
                            (resetPasswordForm.controls.Password.dirty && resetPasswordForm.controls.Password.touched && resetPasswordForm.controls.Password.errors) ||
                            (submitted && resetPasswordForm.controls.Password.errors) ||
                            (showServerErrors && showServerErrors?.password)
                         }"
                            maxlength="16" formControlName="Password">
                        <i class="eye-icon" (click)="hide = !hide" [ngClass]="(hide)?'fa fa-eye-slash':'fa fa-eye'"
                            aria-hidden="true"></i>
                        <div class="error_text mt-0" *ngIf="(resetPasswordForm.controls.Password.dirty && resetPasswordForm.controls.Password.touched && resetPasswordForm.controls.Password.errors) ||
                        (submitted && resetPasswordForm.controls.Password.errors)">
                            <div class="error_text mt-0" *ngIf="resetPasswordForm.controls.Password.errors.pattern">
                                <small>Password must be minimum 8 characters and maximum 16 characters with at
                                    least
                                    1 special
                                    character, 1 number, 1 uppercase and 1 lowercase character.</small>
                            </div>
                            <div class="error_text mt-0" *ngIf="resetPasswordForm.controls.Password.errors.maxlength">
                                Password
                                must be
                                max 16 characters.</div>
                            <div class="error_text mt-0" *ngIf="resetPasswordForm.controls.Password.errors.required">
                                Password is
                                required.
                            </div>
                            <div class="error_text mt-0" *ngIf="resetPasswordForm.controls.Password.errors.cannotContainSpace">
                                Password cannot contain space. </div>
                        </div>
                        <div class="error_text mt-0" *ngIf="showServerErrors && showServerErrors?.password">{{ showServerErrors.password }} </div>
                    </div>
                    <div class="form-group popup-controls mb-2">
                        <label> Confirm New Password<span class="text-danger">*</span></label>
                        <input type="password" class="form-control" placeholder="Confirm Password" minlength="8"
                            [ngClass]="{ 'is-invalid': 
                            (resetPasswordForm.controls.confirmPassword.dirty && resetPasswordForm.controls.confirmPassword.touched && resetPasswordForm.controls.confirmPassword.errors) ||
                            (submitted && resetPasswordForm.controls.confirmPassword.errors)
                         }"
                            maxlength="16" formControlName="confirmPassword">
                        <div class="error_text mt-0" *ngIf="(resetPasswordForm.controls.confirmPassword.dirty && resetPasswordForm.controls.confirmPassword.touched && resetPasswordForm.controls.confirmPassword.errors) ||
                        (submitted && resetPasswordForm.controls.confirmPassword.errors)">
                            <div class="error_text mt-0" *ngIf="resetPasswordForm.controls.confirmPassword.errors">
                                Password and
                                Confirm Password doesn't match.</div>
                        </div>
                    </div>
                    <div class="login-button mt-3 text-center">
                        <button class="jc-button " (click)="resetSubmit()">Confirm</button>
                    </div>

                </form>

            </div>
        </div>
    </div>
</div>
