<app-navigation></app-navigation>

<div class="container-fluid p-0 landing-page-content">
  <div class="landing-banner">
    <!-- <video [muted]="true" class="w-100" autoplay playsinline loop id="myVideo">
            <source [src]="'assets/images/Job_Clicks_New.mp4'" type="video/mp4">
        </video> -->
    <img
      src="assets/images/home-page-images/landing-bg.png"
      class="d-block w-100 banner-bg"
      alt="..."
    />
    <div class="container landing-bg-content">
      <div class="row">
        <div class="col-md-7">
          <img
            src="assets/images/home-page-images/sample-text-image.png"
            class="d-block w-100"
            alt="..."
          />
          <form class="pt-3" [formGroup]="searchJob">
            <div class="row landing-page-search mt-3">
              <div class="col pr-0">
                <div class="form-group landing-controls mb-3">
                  <label class="text-white pl-1"
                    >Job Title<span class="text-danger"></span
                  ></label>

                  <app-autocomplete
                    [invalid]="submitted && searchJob.controls.jobTitle.invalid"
                    [id]="'name'"
                    [text]="'name'"
                    [customInput]="true"
                    [placeholder]="'Search by Skills or Job Title'"
                    [value]="searchJob.value.jobTitle"
                    [options]="
                      searchJobsService?.getJobSearchDropdownDataList
                        ?.jobtitlesandskills
                    "
                    (valueEmit)="clearValue('jobTitle')"
                    (valueSelectedEmit)="setValues($event, 'jobTitle', 'name')"
                  >
                  </app-autocomplete>
                  <div
                    class="error_text mt-0"
                    *ngIf="submitted && searchJob.controls.jobTitle.invalid"
                  >
                    <div
                      class="error_text mt-0"
                      *ngIf="searchJob.controls.jobTitle.errors.required"
                    >
                      Please enter your skills, job title
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group landing-controls mb-3">
                  <label class="text-white pl-1"
                    >Location<span class="text-danger"></span
                  ></label>
                  <app-autocomplete
                    [id]="'name'"
                    [text]="'name'"
                    [placeholder]="'Location'"
                    [value]="searchJob.value.location"
                    [options]="
                      searchJobsService?.getJobSearchDropdownDataList?.location
                    "
                    (valueEmit)="clearValue('location')"
                    (valueSelectedEmit)="setValues($event, 'location', 'name')"
                  >
                  </app-autocomplete>
                </div>
              </div>
              <div class="col-auto d-flex align-items-center mt-2 pl-0">
                <button
                  class="jc-button-small jc-btn-yellow mt-1"
                  (click)="searchJobs()"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col popular-search">
              <span class="popular-search-title d-block">Popular Search:</span>
              <span class="popular-items" (click)="searchJobs('Developer')"
                >Developer</span
              >
              <span class="popular-items" (click)="searchJobs('Marketing')"
                >Marketing</span
              >
              <span class="popular-items" (click)="searchJobs('UX/UI Designer')"
                >UX/UI Designer</span
              >
              <span
                class="popular-items"
                (click)="searchJobs('Human Resources')"
                >Human Resources</span
              >
              <span
                class="popular-items"
                (click)="searchJobs('Project Manager')"
                >Project Manager</span
              >
              <span
                class="popular-items"
                (click)="searchJobs('Dot Net Developer')"
                >Dot Net Developer</span
              >
              <span
                class="popular-items"
                (click)="searchJobs('Full Stack Developer')"
                >Full Stack Developer</span
              >
              <span
                class="popular-items"
                (click)="searchJobs('Business Analysis')"
                >Business Analysis</span
              >
              <span class="popular-items" (click)="searchJobs('Angular')"
                >Angular</span
              >
              <span class="popular-items" (click)="searchJobs('Java Architect')"
                >Java Architect</span
              >
            </div>
          </div>
          <div class="row mt-3">
            <div class="col join-us">
              Update the latest jobs and announcements from Job Clicks.
              <span class="join-us-today cursor-p" (click)="register()"
                >Join us today</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-5 banner-img-xs">
          <img
            src="assets/images/home-page-images/Illustrate-image.png"
            class="d-block w-100"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container py-5 xs-p-2">
    <div class="row aboutus-section">
      <div class="col-lg-7">
        <div class="about-content">
          <span class="bg-span"></span>
          <div class="title">What Makes Us Stand Out</div>
          <div class="content pt-3">
            <p>
              Collaborate and build your dynamic with the most innovative
              companies across the board with us. Engage and Build meaningful
              relationships at scale with us here.
            </p>
            <p>
              Post jobs and receive inbound applications from one of the best
              talent networks. Our expert team works tirelessly to find and
              screen the best remote and flexible jobs, and to provide
              information on each company to help you decide if you want to
              apply. Find your next super talent with our unique system of human
              curation.
            </p>
          </div>
          <img
            src="assets/images/home-page-images/asset2.png"
            class="small-extra-img icon-small-2"
            alt="..."
          />
          <img
            src="assets/images/home-page-images/asset1.png"
            class="small-extra-img icon-small-3"
            alt="..."
          />
          <img
            src="assets/images/home-page-images/asset3.png"
            class="small-extra-img icon-small-1"
            alt="..."
          />
        </div>
      </div>
      <div class="col-lg-5 xs-display-none">
        <div class="aboutus-block p-relative">
          <img
            src="assets/images/home-page-images/section-1-image.png"
            class="d-block about-img w-100"
            alt="..."
          />
        </div>
      </div>
    </div>
    <div class="row about-user-section">
      <div class="col-lg-6">
        <div class="about-user">
          <img
            src="assets/images/home-page-images/i-am-recruiter-BG.png"
            class="d-block w-100"
            alt="..."
          />
          <div class="about-user-content">
            <div class="title mb-3">I am Recruiter</div>
            <p>
              Hiring made simple. Discover talent that fits perfectly with your
              vision. Hire and Assess candidates for diverse domains.
            </p>
            <button class="jc-button" (click)="registerEmployee()">
              Register Account
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="about-user">
          <img
            src="assets/images/home-page-images/i-am-jobseeker-BG.png"
            class="d-block w-100"
            alt="..."
          />
          <div class="about-user-content">
            <div class="title mb-3">I am Applicant</div>
            <p>
              Find a job you love with the us. Browse through numerous dynamic
              opportunities to find your perfect fit.
            </p>
            <button class="jc-button" (click)="register()">
              Register Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- <div class="contailer-fluid way-to-use-employee py-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="way-to-use-employee-option mt-3 row">
                    <div class="employee-option-content col-6 text-center">
                        <span class="d-block"><img src="assets/images/Create_account_icon.png"
                                class="about-img" alt="..."></span>
                        <span class="employee-option-title d-block">Create Acount</span>
                        <span class="employee-option-text d-block">Create an account and access your saved settings
                            on any device.</span>
                    </div>
                    <div class="employee-option-content col-6 text-center">
                        <span><img src="assets/images/Find_job_Vacancy.png" class="about-img"
                                alt="..."></span>
                        <span class="employee-option-title d-block">Find Job Vacancy</span>
                        <span class="employee-option-text d-block">Don't just find. Be found. Put your CV in frount
                            of great employers.</span>
                    </div>
                </div>
                <div class="way-to-use-employee-option mt-4 row justify-content-center">
                    <div class="employee-option-content col-8 text-center">
                        <span><img src="assets/images/Pick_the_best_icon.png" class="about-img"
                                alt="..."></span>
                        <span class="employee-option-title d-block">Get a Job</span>
                        <span class="employee-option-text d-block">Your next career move starts here. Choose Job
                            from thousands of companies.</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 px-5">
                <div class="title">
                    Easiest Way To Use - Employee
                </div>
                <div class="content pt-3">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap.</p>
                </div>
                <div class="employee-content-options">
                    <ul>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                    </ul>
                </div>
                <div class="d-flex">
                    <div class="d-block align-self-center"> <img src="assets/images/looking_for_job_icon.png"
                            class="about-img mr-3" alt="..."></div>
                    <div class="employee-button mx-2 shadow-sm">
                        <span class="employee-option-title d-block">Looking for Job?</span>
                        <span class="apply-now d-flex">Apply Now <span class="apply-now-bar"></span></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contailer-fluid way-to-use-employer py-5">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 px-5">
                <div class="title">
                    Easiest Way To Use - Employer
                </div>
                <div class="content pt-3">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type and scrambled it to make a type specimen book. It has survived not only five
                        centuries, but also the leap.</p>
                </div>
                <div class="employee-content-options">
                    <ul>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                    </ul>
                </div>
                <div class="d-flex">
                    <div class="d-block align-self-center"> <img
                            src="assets/images/Are_you_recruiting_icon.png" class="about-img mr-3" alt="...">
                    </div>
                    <div class="employee-button mx-2 shadow-sm">
                        <span class="employee-option-title d-block">Are You Recruiting?</span>
                        <span class="apply-now d-flex">Post Job Now <span class="apply-now-bar"></span></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="way-to-use-employee-option mt-3 row">
                    <div class="employee-option-content col-6 text-center">
                        <span class="d-block"><img src="assets/images/Create_account_icon.png"
                                class="about-img" alt="..."></span>
                        <span class="employee-option-title d-block">Create Acount</span>
                        <span class="employee-option-text d-block">Create an account and access your saved settings
                            on any device.</span>
                    </div>
                    <div class="employee-option-content col-6 text-center">
                        <span><img src="assets/images/Create_account_icon.png" class="about-img"
                                alt="..."></span>
                        <span class="employee-option-title d-block">Find Job Vacancy</span>
                        <span class="employee-option-text d-block">Don't just find. Be found. Put your CV in frount
                            of great employers.</span>
                    </div>
                </div>
                <div class="way-to-use-employee-option mt-4 row justify-content-center">
                    <div class="employee-option-content col-8 text-center">
                        <span><img src="assets/images/Pick_the_best_icon.png" class="about-img"
                                alt="..."></span>
                        <span class="employee-option-title d-block">Get a Job</span>
                        <span class="employee-option-text d-block">Your next career move starts here. Choose Job
                            from thousands of companies.</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
  <div class="container-fluid featured-jobs py-2">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-between xs-d-unset">
          <div class="">
            <div class="title mb-2">Featured Jobs</div>
            <div class="tag-line">
              Join The Best Well Known Compant Around The World
            </div>
          </div>
          <div class="browse-all" (click)="searchJobs('')">Browse All Jobs</div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <div class="client-logo-block">
            <img
              src="assets/images/google-logo.png"
              class="about-img"
              alt="..."
            />
          </div>
        </div>
        <div class="col">
          <div class="client-logo-block">
            <img
              src="assets/images/Oracle-logo.png"
              class="about-img"
              alt="..."
            />
          </div>
        </div>
        <div class="col">
          <div class="client-logo-block">
            <img
              src="assets/images/Intel-logo.png"
              class="about-img"
              alt="..."
            />
          </div>
        </div>
        <div class="col">
          <div class="client-logo-block">
            <img src="assets/images/IBM-Logo.png" class="about-img" alt="..." />
          </div>
        </div>
        <div class="col">
          <div class="client-logo-block">
            <img
              src="assets/images/Infosys-Logo.png"
              class="about-img"
              alt="..."
            />
          </div>
        </div>
      </div>
      <div class="row mt-4 mb-3">
        <div class="col">
          <div class="suggested-categories">
            <p>Suggested Categories:</p>
            <ul>
              <li class="mx-2" (click)="searchJobs('Developer')">Developer</li>
              <li class="mx-2" (click)="searchJobs('Marketing')">Marketing</li>
              <li class="mx-2" (click)="searchJobs('UX/UI Design')">
                UX/UI Design
              </li>
              <li class="mx-2" (click)="searchJobs('Human Resouces')">
                Human Resouces
              </li>
              <li class="mx-2" (click)="searchJobs('Project Manager')">
                Project Manager
              </li>
              <li class="mx-2" (click)="searchJobs('Finance Consultancey')">
                Finance Consultancey
              </li>
              <li class="mx-2" (click)="searchJobs('Finance')">Finance</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid featured-jobs py-2">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <div class="">
            <div class="title mb-2">How it Works?</div>
            <div class="tag-line">
              Simply Make an account, upload your profile, and you're ready to
              go.
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col d-flex justify-content-center xs-d-unset">
          <div class="jc-steps-content">
            <div class="jc-steps">
              <span class="count">1</span>
              <img
                src="assets/images/Create-account-adduser.svg"
                class="about-img"
                alt="..."
              />
              <span class="yellow-dots-right"></span>
            </div>
            <div class="step-title mb-1">Create Account</div>
            <div class="step-text">
              Create an account and access your saved settings on any device.
            </div>
          </div>
          <div class="jc-steps-content">
            <div class="jc-steps">
              <span class="yellow-dots-left"></span>
              <span class="count">2</span>
              <img
                src="assets/images/find-job-showcase-icon.svg"
                class="about-img"
                alt="..."
              />
              <span class="yellow-dots-right"></span>
            </div>
            <div class="step-title mb-1">Find your Job</div>
            <div class="step-text">Don't just find. Be found.</div>
          </div>
          <div class="jc-steps-content">
            <div class="jc-steps">
              <span class="yellow-dots-left"></span>
              <span class="count">3</span>
              <img
                src="assets/images/get-job-showcase-icon.svg"
                class="about-img"
                alt="..."
              />
            </div>
            <div class="step-title mb-1">Apply & Get Job</div>
            <div class="step-text">
              Your next career move starts here. Choose Job from thousands of
              companies.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid pl-0 py-3 xs-p-3">
    <div class="row jobseeker-section">
      <div class="col-lg-7 xs-display-none">
        <div class="aboutus-block p-relative">
          <img
            src="assets/images/home-page-images/i-am-jobseeker-image.png"
            class="d-block about-img w-100"
            alt="..."
          />
        </div>
      </div>
      <div class="col-lg-5 d-flex align-self-center">
        <div class="jobseeker-content">
          <span class="bg-span"></span>
          <div class="title">I am Applicant</div>
          <div class="content pt-3">
            <p>
              Explore a faster, easier and better job search. The right jobs for
              you are here
            </p>
          </div>
          <button class="jc-button" (click)="browserJobs()">Browse Job</button>
        </div>
        <!-- <img src="assets/images/home-page-images/asset2.png" class="small-extra-img icon-small-2" alt="...">
                <img src="assets/images/home-page-images/asset1.png" class="small-extra-img icon-small-3" alt="...">
                <img src="assets/images/home-page-images/asset3.png" class="small-extra-img icon-small-1" alt="..."> -->
      </div>
    </div>
  </div>
  <div class="container-fluid tech-news tech-news-container py-5">
    <div class="container">
      <div class="title mb-3 text-white">Blogs</div>
      <div class="row">
        <div
          class="col-lg-3 col-sm-6 xs-mb-3"
          *ngFor="let blog of blogsData; let i = index"
        >
          <ng-container *ngIf="i < 4">
            <div class="tech-news-blog">
              <img
                src="{{
                  blog?.fullImagePath
                    ? appService.domain + blog?.fullImagePath
                    : 'assets/images/blog-default.png'
                }}"
                class="d-block w-100 about-img"
                alt="..."
              />
              <div class="tech-news-text">
                <span class="tech-news-text-title">
                  {{ blog?.title | uppercase }}
                </span>
                <p class="blog-content">
                  {{ blog?.blogContent }}
                </p>
                <span class="more" (click)="clickHereBlogs(blog)"
                  >Click here for More...</span
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="container employer-section py-5">
    <div class="row">
      <div class="col text-center">
        <div class="">
          <div class="title mb-2">Employer</div>
          <div class="tag-line">
            Make recruiting your competitive edge. Browse through hundreds of
            resumes and find the perfect professional for your job.
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div
        class="col-md-6 col-mobile d-flex justify-content-center align-items-center"
      >
        <div class="employer-content">
          <div class="title mb-3">Employer</div>
          <p>
            Say Hola to the talent pool here! With numerous expectant job
            aspirants here, you'll have a volume of audience you can't get
            anywhere else. Our unique curation system works helps sort and
            recommend you the right talent. Everything we know goes into
            matching technology, so can always recommend you the right talent
            you can hire faster.
          </p>
          <div class="row">
            <div class="col">
              <div class="title-small">Discover</div>
              <p>
                Our unique curation system works helps sort and recommend you
                the right talent. We match businesses of all sizes with the best
                people for their open roles.
              </p>
            </div>
            <div class="col">
              <div class="title-small">Hire</div>
              <p>
                Everything we know goes into matching technology, so can always
                recommend you the right talent you can hire faster. Tap into New
                and High-Value Candidate Pools
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-mobile-img">
        <div class="aboutus-block p-relative">
          <img
            src="assets/images/home-page-images/employer-llustrate-asset.png"
            class="d-block about-img w-100"
            alt="..."
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid p-0 subscribe-newsletter">
    <img
      src="assets/images/home-page-images/upload-resume-bg.png"
      class="d-block about-img w-100"
      alt="..."
    />
    <div class="container subscribe-newsletter-content">
      <div class="row">
        <div class="col-md-6 col-mobile-img aboutus-block xs-display-none">
          <img
            src="assets/images/home-page-images/hiring-upload resume.png"
            class="d-block about-img w-100"
            alt="..."
          />
        </div>
        <div class="col d-flex align-items-center">
          <div class="input-content">
            <div class="subscribe-title mb-2">Subscribe Our Newsletter!</div>
            <div class="mb-2 text-white">
              <p>
                Subscribe to stay on top of shifting global markets. Delivered
                straight to your inbox.
              </p>
            </div>
            <form [formGroup]="emailForm">
              <div class="form-group subscribe-newsletter-input d-flex">
                <input
                  type="text"
                  id="forgotEmail"
                  class="form-control"
                  formControlName="Email"
                  placeholder="Email Address"
                  [ngClass]="{
                    'is-invalid':
                      (emailForm.controls.Email.dirty &&
                        emailForm.controls.Email.touched &&
                        emailForm.controls.Email.errors) ||
                      (submitted && emailForm.controls.Email.errors)
                  }"
                />
                <button class="jc-button" (click)="saveContactDetails()">
                  Submit
                </button>
              </div>
              <div
                class="error_text mt-0"
                *ngIf="
                  (emailForm.controls.Email.dirty &&
                    emailForm.controls.Email.touched &&
                    emailForm.controls.Email.errors) ||
                  (submitted && emailForm.controls.Email.errors)
                "
              >
                <div
                  class="error_text mt-0"
                  *ngIf="
                    emailForm.controls.Email.errors &&
                    emailForm.controls.Email.errors.pattern
                  "
                >
                  Please enter a valid Email.
                </div>
                <div
                  class="error_text mt-0"
                  *ngIf="
                    emailForm.controls.Email.errors &&
                    emailForm.controls.Email.errors.required
                  "
                >
                  Email is required.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
