import { SpinnerService } from './spinner.service';
import { CommonService } from './common.service';
import { AppService } from './app.service';
import { Injectable } from '@angular/core';
import { HttpMethod } from '../enums/http-handlers';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EmployerService {

  remindersCount = 0;
  employerReminders = []

  userResponsibilities: any;
  getUserAndJobStatusCodesData: any;
  companyLogoPath = '';

  getActiveindustriesList = [];

  constructor(private appService: AppService,
    private commonService: CommonService,
    private router: Router,
    private spinnerService: SpinnerService) { }

  getEmployerReminderCount() {
    this.employerReminders = [];
    this.remindersCount = 0;
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const apiObj = {
      url: `${this.appService.getEmployerRemindersCount}?employerId=${loginData.id}`,
      methodType: HttpMethod.GET,
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag) {
        if (res.data.hasOwnProperty('employerReminders') && Object.keys(res.data.employerReminders).length) {
          this.employerReminders = res.data.employerReminders;
          this.employerReminders.forEach(resp => this.remindersCount = this.remindersCount + resp.count)
        }
      }
    }
    )
  }

  getEmployerUserResponsibilities() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    const apiObj = {
      url: `${this.appService.getEmployerUserResponsibilities}?userId=${loginData.userId}`,
      methodType: HttpMethod.GET,
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.getEmployerReminderCount();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data && res.data.length) {
        this.userResponsibilities = res.data;
      }
    })
  }

  getUserAndJobStatusCodes(callBack) {
    if(this.getUserAndJobStatusCodesData) {
      callBack(true)
      return;
    }
    const apiObj = {
      url: `${this.appService.getUserAndJobStatusCodes}`,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, (res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data) {
        this.getUserAndJobStatusCodesData = res.data;
        callBack(true)
      }
    })
  }

  checkEmployerResponsibilities(path, action) {
    if(this.userResponsibilities && this.userResponsibilities.length) {
      if (path.includes('/vendor')) {
        return this.addUpdateViewStatus(action, 'VMS-Vendor')
      } else if (path.includes('/client')) {
        return this.addUpdateViewStatus(action, 'VMS-Client')
      } else if (path.includes('/manage-job')) {
        return this.addUpdateViewStatus(action, 'Manage Job Posting')
      } else if (path.includes('/manage-drafts')) {
        return this.addUpdateViewStatus(action, 'Manage Job Posting')
      } else if (path.includes('/users')) {
        return this.addUpdateViewStatus(action, 'User')
      }
    }
    return true
  }

  addUpdateViewStatus(action, name) {
    let obj = this.userResponsibilities.find(res => res.responsibilityName === name)
    if(obj) {
      if (action.includes('/create-vendor') || action.includes('/create-client') || action.includes('/jobpost') || action.includes('/add-users')) {
        return obj.isAdded;
      } else if(action.includes('/update-vendor') || action.includes('/update-client') || action.includes('/update-post-job') || action.includes('/update-users')) {
        return obj.isUpdated
      } else if(action.includes('/vendor-overview') || action.includes('/client-overview') || action.includes('/post-job-preview') || action.includes('/users-overview')) {
        return obj.isViewed
      } else if(action.includes('status')) {
        return obj.isStatused
      }
    } else {
      return false
    }
  }

  openProfile(e) {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if(!loginData) {
      this.router.navigateByUrl('');
    }
    if (e === 'profile') {
      switch (loginData.roleName) {
        case 'employer_systemadmin':
          this.router.navigateByUrl('/auth/employer/company/companyProfile-overview');
          break;
        case 'employer_admin':
          this.router.navigateByUrl('/auth/employer/admin/adminProfile-overview');
          break;
        case 'employer_recruiter':
          this.router.navigateByUrl('/auth/employer/recruiter/recruiterProfile-overview');
          break;
      }
    } else if (e === 'editProfile') {
      switch (loginData.roleName) {
        case 'employer_systemadmin':
          this.router.navigateByUrl('/auth/employer/company/update-companyProfile');
          break;
        case 'employer_admin':
          this.router.navigateByUrl('/auth/employer/admin/update-adminProfile')
          break;
        case 'employer_recruiter':
          this.router.navigateByUrl('/auth/employer/recruiter/update-recruiterProfile');
          break;
      }
    } else if (e === 'createProfile') {
      switch (loginData.roleName) {
        case 'employer_systemadmin':
          this.router.navigateByUrl('/auth/employer/company/create-companyProfile');
          break;
        case 'employer_admin':
          this.router.navigateByUrl('/auth/employer/admin/create-adminProfile');
          break;
        case 'employer_recruiter':
          this.router.navigateByUrl('/auth/employer/recruiter/create-recruiterProfile');
          break;
      }
    }
  }

  getActiveindustries(callBack) {
    if(this.getActiveindustriesList && this.getActiveindustriesList.length) {
      callBack(true)
      return
    }
    const url = `${this.appService.getAllActiveIndustries}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET,
      showSpinner: false
    }
    this.commonService.commonApiCall(apiObj,(res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data') && res.data && res.data.hasOwnProperty('industries') && res.data.industries && res.data.industries.length) {
        this.getActiveindustriesList = res.data.industries;
        callBack(true)
      }
    });
  }


}
