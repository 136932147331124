<div class="container-fluid p-0">
  <img src="assets/images/Blog-banner.png" class="d-block w-100" alt="..." />
  <div class="container">
    <div class="row mt-5">
      <div class="col-md-8">
        <ng-container
          *ngFor="
            let blog of getAllBlogDetailsList
              | paginate
                : {
                    itemsPerPage: pageSize,
                    currentPage: p,
                    totalItems: noOfPages
                  };
            let i = index
          "
        >
          <div class="col">
            <!-- <span> {{  }} </span> -->
            <div class="blog-category-name">
              <span class="border-content"></span>
              <span class="category-blog-name">{{
                blog?.blogCategoryName | uppercase
              }}</span>
            </div>
            <div class="blog-name">
              <span class="blog-name-span">
                {{ blog?.title | uppercase }}
              </span>
              <div class="created-name">
                <span> {{ blog?.createdByName | uppercase }}</span> |
                <span> {{ blog?.createdOn | date : "dd/MMM/yyyy" }} </span>
              </div>
            </div>
          </div>
          <div class="blog-image">
            <img
              src="{{
                blog?.fullImagePath
                  ? appService.domain + blog?.fullImagePath
                  : 'assets/images/blog-default.png'
              }}"
              class="d-block w-100"
              alt="..."
            />
          </div>
          <!-- add this class show-all-content -->
          <div
            class="blog-text-content"
            [ngClass]="{ 'show-all-content': blog?.showFull }"
          >
            {{ blog?.blogContent }}
          </div>
          <div class="d-flex justify-content-end mb-5">
            <button class="jc-button" (click)="showFullContent(blog)">
              Read {{ blog?.showFull ? "Less" : "More" }}
            </button>
            <!-- <button class="jc-button share-btn pr-2  mr-3"><img src="assets/images/share_post.svg"
                                alt="location"> Share</button> -->
            <!-- <button class="share-btn " (click)="registerEmployee()">Share</button> -->
          </div>
        </ng-container>

        <div *ngIf="!getAllBlogDetailsList.length">No Blogs Found</div>

        <pagination-controls
          *ngIf="getAllBlogDetailsList.length"
          (pageChange)="p = $event"
          (pageChange)="handlePageChange($event)"
        >
        </pagination-controls>
      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="recent-search">
            <div class="search-design mt-3">
              <div class="input-group">
                <input
                  type="text"
                  [(ngModel)]="filterString"
                  (input)="onFilterChange()"
                  class="form-control"
                  placeholder="Select for blog"
                />
                <div class="search-icon">
                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <span class="categories"> Categories </span>
            <div class="row bg-change m-0" (click)="setBlog('All')">
              <div class="col-8">
                <h6 class="mb-3 cursor-p">All</h6>
              </div>
            </div>
            <ng-container *ngFor="let count of filtered; let i = index">
              <div class="row bg-change m-0" (click)="setBlog(count)">
                <div class="col-8 cursor-p">
                  <h6>{{ count?.blogCategoryName }}</h6>
                </div>
                <div class="col text-right">
                  <p>{{ count?.blogsCount }}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="row m-0">
          <div class="last-post card">
            <h3 class="categories">Last Post</h3>
            <ng-container
              *ngFor="let last of getAllLastBlogDetailsList; let i = index"
            >
              <div
                class="row bg-change m-0 mb-3 d-flex"
                (click)="setLastBlog(last)"
              >
                <div class="d-flex align-items-center">
                  <div>
                    <img
                      src="{{
                        last?.thumbnailPath
                          ? appService.domain + last?.thumbnailPath
                          : 'assets/images/Blog-banner.png'
                      }}"
                      alt="..."
                      class="post-image"
                    />
                  </div>
                  <div class="col ml-3">
                    <h6>{{ last?.title | uppercase }}</h6>
                    <span class="created-name">
                      {{ last?.createdByName | uppercase }}</span
                    >
                    |<span class="created-name">
                      {{ last?.createdOn | date : "dd/MMM/yyyy" }}
                    </span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
