import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpMethod } from '../../../core/enums/http-handlers';
import { AppService } from '../../../core/services/app.service';
import { CommonService } from '../../../core/services/common.service';
import { SpinnerService } from '../../../core/services/spinner.service';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  getAllBlogDetailsList: any[] = [];
  getAllLastBlogDetailsList: any[] = [];
  getBlogCategoriesWithBlogsCountList: any[] = [];

  filtered: any;
  filterString = "";

  p = 1
  pageSize = 3;
  noOfPages: any;

  constructor(
    public appService: AppService,
    private commonService: CommonService,
    private spinnerService: SpinnerService,
    private router: ActivatedRoute
  ) {
    this.router.params.subscribe(res => {
      if (res.hasOwnProperty('id') && res.id) {
        this.getAllBlogDetails(this.p, this.pageSize, res.id, 'getAllBlogDetailsList');
      } else {
        this.getAllBlogDetails(this.p, this.pageSize, 0, 'getAllBlogDetailsList');
      }
      this.getAllBlogDetails(this.p + 1, this.pageSize, 0, 'getAllLastBlogDetailsList');
      this.getBlogCategoriesWithBlogsCount();
    })
   }


  ngOnInit(): void {
  }

  showFullContent(blog) {
    blog.showFull = !blog.showFull
  }

  handlePageChange(event) {
    this.p = event;
    this.getAllBlogDetails(this.p, this.pageSize, 0, 'getAllBlogDetailsList');
  }

  onFilterChange() {
    this.filtered = this.getBlogCategoriesWithBlogsCountList.filter((list) => this.isMatch(list));
  }

  isMatch(item: any): any {
    return item.blogCategoryName.toString().toLocaleLowerCase().indexOf(this.filterString.toLocaleLowerCase()) > -1
  }

  setBlog(blog) {
    this.p = 1;
    this.getAllBlogDetails(this.p, this.pageSize, blog == 'All' ? 0 : blog.blogCategoryId, 'getAllBlogDetailsList');
  }

  setLastBlog(blog) {
    this.p = 1
    this.pageSize = 3;
    this.noOfPages = null;
    this.getAllBlogDetailsList = [blog];
  }

  getAllBlogDetails(pn: any, rpp: any, bc: any, key) {
    this[key] = [];
    const url = `${this.appService.getAllBlogDetails}?pageNumber=${pn}&resultsPerPage=${rpp}&blogCategoryId=${bc}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.data && res.data.hasOwnProperty('blogsData') && res.data.blogsData && res.data.blogsData.length) {
        this[key] = res.data.blogsData;
        if (pn == 1 && key == 'getAllBlogDetailsList') {
          this.noOfPages = res.data.totalNoOfBlogs;
        }
      }
    }));
  }

  getBlogCategoriesWithBlogsCount() {
    this.getBlogCategoriesWithBlogsCountList = [];
    const url = `${this.appService.getBlogCategoriesWithBlogsCount}`;
    const apiObj = {
      url: url,
      methodType: HttpMethod.GET
    }
    this.commonService.commonApiCall(apiObj, ((res, statusFlag) => {
      this.spinnerService.hide();
      if (statusFlag && res && res.hasOwnProperty('data')) {
        this.getBlogCategoriesWithBlogsCountList = res.data;
        this.onFilterChange();
      }
    }));
  }


}
