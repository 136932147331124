<div class="footer mt-4">
    <div class="container">
        <div class="row footer-content">
            <div class="col-md-4 mb-4">
                <div class="footer-logo">
                    <h3 class="logo">JOB Clicks</h3><!-- <span>Logo</span>-->
                    <p>Bridging the gap between people and opportunity. Specializing in hiring and networking.</p>
                    <div class="row ">
                        <div class="col-xs-6 col-sm-6 col-md-6">
                            <h5>Contact us</h5>
                            <div class="social-icon">
                                <img src="assets/images/phone-call (3).png" alt=""><span>+1-202-555-0104</span> <br>
                                <img src="assets/images/mail (1).png" width="8px" height="8px" alt=""><span>example@gmail.com</span>
                            </div>
                        </div>
                        <div class="col-xs-6 col-sm-6 col-md-4 text-center">
                            <div class="follow">
                                <h5 class="">Follow us</h5>
                                <a href=""><img src="assets/images/facebook.png" alt=""></a>
                                <a href=""><img src="assets/images/twitter.png" alt=""></a>
                                <a href=""><img src="assets/images/instagram.png" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 footer-menu mb-3">
                <h5 class="footer-sec-header">Information</h5>
                <a href="">About us</a>
                <a href="">Teams and Conditions</a>
                <a href="">Privacy Policy</a>
                <a href="">Contact us</a>
                <a href="">FAQs</a>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 footer-menu mb-3">
                <h5 class="footer-sec-header">Browse Jobs</h5>
                <a class="cursor-p" (click)="route('/find-Job')"  >Jobs by Designation</a>
                <a class="cursor-p" (click)="route('/find-Job')">Jobs by Location</a>
                <a class="cursor-p" (click)="route('/find-Job')" >Jobs by Skills</a>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 footer-menu mb-3">
                <h5 class="footer-sec-header">Applicants</h5>
                <a (click)="showRegisterModel()">Register Now</a>
                <a (click)="loginJobSeeker()">Login</a>
                <a class="cursor-p" (click)="route('/find-Job')">Search Job</a>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-2 footer-menu mb-3">
                <h5 class="footer-sec-header">Employers</h5>
                <a href="">Job Posting</a>
                <a href="">Search Resumes</a>
                <a href="">Employer Login</a>
            </div>

        </div>
    </div>
    <div class="row footer-bar">
        <div class="col">
            <p class="text-center">Copyright © 1990 - 2021 Dice. All rights reserved. Use of this site is subject to certain Terms and Conditions</p>
        </div>
    </div>
</div>
