<div class="card find-job">
  <form [formGroup]="createAlertForm">
    <label class="search-label jc-text-normal mb-1"
      >Job Title(Designation)<span class="text-danger">*</span></label
    >
    <app-autocomplete
      [id]="'jobTitle'"
      [text]="'jobTitle'"
      [customInput]="true"
      [invalid]="submitted && createAlertForm.controls.jobTitle.invalid"
      [placeholder]="'Search for Jobs, Skills'"
      [value]="createAlertForm.value.jobTitle"
      [options]="searchJobsService.getJobSearchDropdownDataList.jobTitles"
      (valueEmit)="clearValue('jobTitle')"
      (valueSelectedEmit)="setValues($event, 'jobTitle', 'jobTitle')"
    >
    </app-autocomplete>
    <div
      class="error_text mt-0"
      *ngIf="submitted && createAlertForm.controls.jobTitle.invalid"
    >
      <div
        class="error_text mt-0"
        *ngIf="createAlertForm.controls.jobTitle.errors.required"
      >
        Please Enter JobTitle
      </div>
    </div>
    <label class="search-label jc-text-normal mt-2 mb-1"
      >Name the Alert<span class="text-danger">*</span></label
    >
    <input
      type="text"
      class="form-control"
      formControlName="jobAlertName"
      [ngClass]="{
        'is-invalid':
          (createAlertForm.controls.jobAlertName.dirty &&
            createAlertForm.controls.jobAlertName.touched &&
            createAlertForm.controls.jobAlertName.errors) ||
          (submitted && createAlertForm.controls.jobAlertName.errors) ||
          (showServerErrors && showServerErrors?.jobalert)
      }"
    />
    <div
      class="error_text mt-0"
      *ngIf="
        (createAlertForm.controls.jobAlertName.dirty &&
          createAlertForm.controls.jobAlertName.touched &&
          createAlertForm.controls.jobAlertName.errors) ||
        (submitted && createAlertForm.controls.jobAlertName.errors)
      "
    >
      <div
        class="error_text mt-0"
        *ngIf="
          createAlertForm.controls.jobAlertName.errors &&
          createAlertForm.controls.jobAlertName.errors.required
        "
      >
        Alert Name is required.
      </div>
      <div
        class="error_text mt-0"
        *ngIf="
          createAlertForm.controls.jobAlertName.errors &&
          createAlertForm.controls.jobAlertName.errors.pattern
        "
      >
        Please enter valid Alert Name.
      </div>
    </div>
    <div
      class="error_text mt-0"
      *ngIf="showServerErrors && showServerErrors?.jobalert"
    >
      {{ showServerErrors.jobalert }}
    </div>
    <button class="jc-button w-100 mt-3" (click)="submitCreateAlert()">
      Save as Alert
    </button>
  </form>
</div>
