import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { CommonService } from './core/services/common.service';
import { AppService } from './core/services/app.service';
import { SpinnerService } from './core/services/spinner.service';
import { HttpMethod } from './core/enums/http-handlers';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { TokenService } from './core/services/token.service';
import { ApiService } from './core/services/api.service';

const TOKEN_HEADER_KEY = 'Authorization';  // for Spring Boot back-end


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  currentUser: any;

  constructor(
    private commonService: CommonService,
    private appService: AppService,
    private apiService: ApiService,
    private router: Router,
    private spinnerService: SpinnerService,
    private tokenService: TokenService
  ) { }

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let authReq = req;
    const token = this.tokenService.getToken();
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq).pipe(catchError(err => {
      this.spinnerService.hide();
      const error = (err && err.error) ? err.error.message || err.statusText : 'error occured';
      if (err.status !== 401) {
        return throwError(error);
      }
      if (err instanceof HttpErrorResponse && err.status === 401) {
        return this.handle401Error(authReq, next);
      } else {
        this.commonService.clearStorage();
        this.commonService.snackBar('Session Experied', 'error')
        this.router.navigateByUrl('')
        return throwError('Session Experied');
      }
    }));
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // if (!this.isRefreshing) {
      // this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      const token = this.tokenService.getRefreshToken();
      if (token)
        return this.apiService.refreshToken(token).pipe(
          switchMap((res: any) => {
            // this.isRefreshing = false;
            if (res.data.hasOwnProperty('accessToken')) {
              this.tokenService.saveToken(res.data.accessToken);
            }
            if (res.data.hasOwnProperty('refreshtoken')) {
              this.tokenService.saveRefreshToken(res.data.refreshtoken);
            }
            this.refreshTokenSubject.next(res.data.accessToken);
            return next.handle(this.addTokenHeader(request, res.data.accessToken));
          }),
          catchError((err) => {
            // this.isRefreshing = false;
            this.commonService.clearStorage();
            this.commonService.snackBar('Session Experied', 'error')
            this.router.navigateByUrl('')
            return throwError('Session Experied');
          })
        );
    // }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addTokenHeader(request, token)))
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    const newToken = `Bearer ${token}`
    return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, newToken) });
  }

}

