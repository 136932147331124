import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CommonService } from '../../core/services/common.service';
import { SearchJobsService } from '../../core/services/search-jobs.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {

 // form models
 searchJob: FormGroup;

 // to show input error messgae
submitted = false;

constructor(
 private fb: FormBuilder,
 public commonService: CommonService,
 private route: Router,
 private router: ActivatedRoute,
 public searchJobsService: SearchJobsService,
 private _location: Location,
) {
 const loginData = JSON.parse(localStorage.getItem('loginData'));
 if(loginData) {
   switch (loginData.roleName) {
     case 'employee':
       this.route.navigateByUrl('/auth/employee/home');
       break;
       default:
       this.route.navigateByUrl('/auth/employer/home');
       break;
   }
 }
 localStorage.setItem('nonUser', 'true')
 this.searchJobsService.getJobSearchDropdownData();
 this.route.events.subscribe((event) => {

   if (event instanceof NavigationStart) {
       // Show loading indicator
   }

   if (event instanceof NavigationEnd) {
       // Hide loading indicator
       this.submitted = false;
   }

   // if (event instanceof NavigationError) {
   //     // Hide loading indicator

   //     // Present error to user
   // }

});

}

ngOnInit(): void {
 this.searchJobModel();
 this.searchJob.patchValue(this.router.queryParams['_value']);
}

searchJobModel() {
 this.searchJob = this.fb.group({
   jobTitle: ['', Validators.required],
   location: [''],
   searchType: ['BASIC']
 });
}

clearValue(key) {
 this.searchJob.patchValue({
   [key]: ''
 })
}

setValues(value, key, id?) {
 this.searchJob.patchValue({
   [key]: id ? value[id] : value
 })
}

basicSearchJob() {
 this.submitted = true;
 if(this.searchJob.invalid) {
   return
 }
 this.route.navigate(['job-search'], { queryParams: this.searchJob.value })
}

back() {
 this.commonService.back();
}

}

