import { Component } from '@angular/core';
import { SpinnerService } from './core/services/spinner.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonService } from './core/services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  showSpinner: boolean;

  constructor(public spinnerService: SpinnerService, private commonService: CommonService, private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if(e.url.includes('set-password')) {
          return
        }
        const loginData = JSON.parse(localStorage.getItem('loginData'));
        const nonUser = localStorage.getItem('nonUser');

        if (!(loginData || nonUser)) {
          this.router.navigateByUrl('/home');
        }
      }
    });

    window.addEventListener('storage', (res) => {
      // When local storage changes, dump the list to
      // the console.
      if(res.key == "refresh" && res.newValue == "true") {
      localStorage.setItem('refresh', 'false');
        window.location.reload();
      }
      if(res.key != 'copyData') {
        this.checkLogin();
      }
    });
  }

  checkLogin() {
    const loginData = JSON.parse(localStorage.getItem('loginData'));
    if (loginData && loginData.id) {
      this.commonService.loginRoute();
    } else {
      this.router.navigateByUrl('');
    }
  }


}
